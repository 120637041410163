import React, { useState } from "react";
import restApi from '../../../api/Source';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import ReactSpinner from 'react-bootstrap-spinner';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import SearchBar from "../../../components/user/SearchBar/SearchBar";

const PAGE_USERS = 'users';
const PAGE_ORDERS = 'orders';
const PAGE_PRODUCTS = 'adminproducts';
const PAGE_INVOICES = 'invoices';

function ProductManagement(props) {

    const isLoading = props.isLoading;
    const exchange = props.exchange;
    const [showEditProduct, setShowEditProduct] = useState(false);
    const [showEditProvider, setShowEditProvider] = useState(false);
    const [selectedEditProduct, setSelectedEditProduct] = useState({});
    const [selectedEditProvider, setSelectedEditProvider] = useState({});
    const [newAdditionalCrossReference, setNewAdditionalCrossReference] = useState('');
    const [manualInvoiceNumber, setManualInvoiceNumber] = useState('');
    const [menuHierarchy, setMenuHierarchy] = useState();

    const [uploadProvidersResult, setUploadProvidersResult] = useState([]);
    const [newMenuForProduct, setNewMenuForProduct] = useState([]);

    const [manageOption, setManageOption] = useState({
        displayAddProduct: false,
        displayModifyProduct: false,
        displayExchange: false,
        displayManualInvoice: false,
        displayPrices: false,
        displayUploadProviders: false,
        displayUniversalMenu: false
    });

    const [newProduct, setNewProduct] = useState({
        articleNumber: '',
        productName: '',
        supplierId: '',
        supplierBrand: '',
        articleCriteria: '',
        eanNumbers: '',
        oemNumbers: '',
        price: 0,
        imagePath: ''
    });
    // for image upload
    const [selectedFile, setSelectedFile] = useState(null);

    const [formError, setFormError] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    
    const navigateTo = (page) => {
        console.log(page);
        props.history.push(page);
        props.setProducts([]);
    }
    
    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "ADMIN") {
        navigateTo('/');
    }

    const handleLogout = () => {
        props.handleLogout();
        navigateTo('/');
    }

    const close = () => {
       setShowEditProduct(false);
       setShowEditProvider(false);
    }

    const manageUsers = () => {
        props.getUsers();
        navigateTo(PAGE_USERS);
    }

    const manageOrders = () => {
        navigateTo(PAGE_ORDERS);
    }

    const manageProducts = () => {
        navigateTo(PAGE_PRODUCTS);
    }

    const manageInvoices = () => {
        props.getInvoices();
        navigateTo(PAGE_INVOICES);
    }

    const getProducts = (articleNumber) => {
        props.getProducts(false, articleNumber);
    }

    const viewExchange = () => {
        props.getExchange();
        setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': false, 'displayExchange': true, 'displayManualInvoice': false, 'viewPrices': false, 'displayUploadProviders': false, 'displayUniversalMenu': false});
        props.setProducts([]);
        setSelectedFile(null);
    }

    const viewManualInvoice = () => {
        setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': false, 'displayExchange': false, 'displayManualInvoice': true, 'viewPrices': false, 'displayUploadProviders': false, 'displayUniversalMenu': false});
        props.setProducts([]);
        setSelectedFile(null);
    }

    const viewUniversalMenu = () => {
        props.setLoading(true);
        restApi.get("/services/menu/hierarchy").then(menuHierarchyResponse => {
            setMenuHierarchy(menuHierarchyResponse.data);
            props.setLoading(false);

            setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': false, 'displayExchange': false, 'displayManualInvoice': false, 'viewPrices': false, 'displayUploadProviders': false, 'displayUniversalMenu': true});
            props.setProducts([]);
        }).catch(error => {
            props.setLoading(false);
            if (error.response.status === 401) {
                navigateTo('/');
            }
        });
        setSelectedFile(null);
        
    }

    const viewPrices = () => {
        setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': false, 'displayExchange': false, 'displayManualInvoice': false, 'viewPrices': true, 'displayUploadProviders': false, 'displayUniversalMenu': false});
        props.setProducts([]);
        setSelectedFile(null);
    }

    const viewProviders = () => {
        setUploadProvidersResult([]);
        setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': false, 'displayExchange': false, 'displayManualInvoice': false, 'viewPrices': false, 'displayUploadProviders': true, 'displayUniversalMenu': false});
        props.setProducts([]);
        setSelectedFile(null);
    }

    const viewModifyProduct = () => {
        setManageOption({...manageOption, 'displayAddProduct': false, 'displayModifyProduct': true, 'displayExchange': false, 'displayManualInvoice': false, 'viewPrices': false, 'displayUploadProviders': false, 'displayUniversalMenu': false});
        setSelectedFile(null);
    }

    const viewAddProduct = () => {
        setManageOption({...manageOption, 'displayAddProduct': true, 'displayModifyProduct': false, 'displayExchange': false, 'displayManualInvoice': false, 'viewPrices': false, 'displayUploadProviders': false, 'displayUniversalMenu': false});
        props.setProducts([]);
        setSelectedFile(null);
    }

    const handleEditExchange = (e) => {
        if (e.target.value !== undefined) {
            props.setExchange({...exchange, 'value': e.target.value});
        }
    }

    const handleManualInvoice = (e) => {
        console.log(e.target.value);
        if (e.target.value !== undefined) {
            setManualInvoiceNumber(e.target.value);
        }
    }

    const generateManualInvoice = async() => {
        const config = { headers: {'Content-Type': 'application/json'} };

        console.log(manualInvoiceNumber);

        await restApi.post("/services/invoice/generate", manualInvoiceNumber, config).then(res => {
            console.log(res);
        }).catch(error => {
            console.log("Something didn't work");
        })
    }

    const editExchange = () => {
        props.editExchange();
    }

    const handleGeneratePricesStocksForBrand = (e) => {
        if (e.target.value !== undefined) {
            props.setPricesStockForBrand(e.target.value);
        }
    }

    const generatePricesStocksForBrand = () => {
        props.generatePricesStocksForBrand();
    }

    const generatePricesStocksForAllBrands = () => {
        props.generatePricesStocksForAllBrands();
    }

    const handleEditProduct = (product) => {
        setSelectedEditProduct(product);
        setShowEditProduct(true);
    }

    const handleEditProvider = (provider) => {
        setSelectedEditProvider(provider);
        setShowEditProvider(true);
    }

    const handleProductShelfChange = (e) => {
        selectedEditProduct.shelf = e.target.value;
    }

    const handleProductAdditionChange = (e) => {
        selectedEditProduct.addition = e.target.value;
    }

    const handleEditProviderChange = (detail, e) => {
        switch(detail) {
            case 'acquisitionPrice':
                selectedEditProvider.acquisitionPrice = e.target.value;
                break;
            case 'sellingPrice':
                selectedEditProvider.sellingPrice = e.target.value;
                break;
            case 'stock':
                selectedEditProvider.stock = e.target.value;
                break;
        }
    }

    const editProduct = () => {
        props.editProduct(selectedEditProduct);
        setShowEditProduct(false);
    }

    const editProvider = () => {
        props.editProvider(selectedEditProvider);
        setShowEditProvider(false);
    }

    // ADD new product page
    const updateNewProduct = e => {
        setNewProduct({...newProduct, [e.target.name]: e.target.value});
        console.log(newProduct);
    }

    const removeCrossReference = async(additionalCrossReference) => {
        const request = {
            articleNumber: selectedEditProduct.articleNumber,
            additionalCrossReference: additionalCrossReference
        };
    
        await restApi.post("/services/product/remove/cross", request).then(res => {
            console.log(res);
            // remove the cross reference from the object
            let array = selectedEditProduct.additionalCrossReferences.filter(item => item !== additionalCrossReference);
            const newSelectedEditProduct = { ...selectedEditProduct, additionalCrossReferences: array };
            setSelectedEditProduct(newSelectedEditProduct);
        }).catch(error => {
            console.log("Something didn't work");
        })
    }

    const handleNewAdditionalCrossReference = (e) => {
        setNewAdditionalCrossReference(e.target.value);
    }

    const handleAssignMenuForProduct = (e) => {
        setNewMenuForProduct(e.target.value);
    }

    const addCrossReference = async() => {
        const request = {
            articleNumber: selectedEditProduct.articleNumber,
            additionalCrossReference: newAdditionalCrossReference
        };
    
        await restApi.post("/services/product/add/cross", request).then(res => {
            console.log(res);
            // add the cross reference to the object
            let array = selectedEditProduct.additionalCrossReferences.concat(newAdditionalCrossReference);
            const newSelectedEditProduct = { ...selectedEditProduct, additionalCrossReferences: array };
            setSelectedEditProduct(newSelectedEditProduct);
            setNewAdditionalCrossReference('');
        }).catch(error => {
            console.log("Something didn't work");
        })
    }

    const assignMenuForProduct = async() => {
        const request = {
            articleNumber: selectedEditProduct.articleNumber,
            supplierId: selectedEditProduct.supplierId,
            menu: newMenuForProduct
        };
    
        await restApi.post("/services/product/add/menu", request).then(res => {
            console.log(res);
            setNewMenuForProduct('');
        }).catch(error => {
            console.log("Something didn't work");
        })
    }

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setSelectedFile(file);
    };

    const handleUploadImage = async (eventType) => {
        if (!selectedFile) {
            setFormResponse('Please select an image file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await restApi.post('/services/product/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            console.log("Response Status:", response.status);
    
            if (response.status === 200) {
                console.log("File uploaded successfully");
                setFormResponse(response.data);
                if (eventType === 'create') {
                    setNewProduct({ ...newProduct, imagePath: 'additional-products/' + selectedFile.name });
                } else {
                    setSelectedEditProduct({
                        ...selectedEditProduct,
                        media: { ...selectedEditProduct.media, source: 'additional-products/' + selectedFile.name }
                    });
                }
                
            } else {
                setFormResponse('Unexpected response status: ' + response.status);
            }
            
        } catch (error) {
            if (error.response) {
                console.log("Error Response Status:", error.response.status);
                console.log("Error Response Data:", error.response.data);
    
                if (error.response.status === 409) {
                    console.log("File Name on Conflict:", selectedFile.name);
                    setFormResponse('Image already exists, proceeding with the existing image.');
                    if (eventType === 'create') {
                        setNewProduct({ ...newProduct, imagePath: 'additional-products/' + selectedFile.name });
                    } else {
                        setSelectedEditProduct({
                            ...selectedEditProduct,
                            media: { ...selectedEditProduct.media, source: 'additional-products/' + selectedFile.name }
                        });
                    }
                } else {
                    setFormResponse('Failed to upload image. Status: ' + error.response.status);
                }
            } else {
                setFormResponse('Error: ' + error.message);
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        setSelectedEditProduct({
            ...selectedEditProduct,
            media: { ...selectedEditProduct.media, source: '' }
        });
        setNewProduct({ ...newProduct, imagePath: '' });
        setFormResponse('');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        console.log("Form submission triggered"); // Debug: Ensure the function is triggered
    
        setFormResponse(''); // Reset form response
    
        // Construct product object with the current state
        const product_object = {
            articleNumber: newProduct.articleNumber,
            productName: newProduct.productName,
            supplierId: newProduct.supplierId,
            supplierBrand: newProduct.supplierBrand,
            articleCriteria: newProduct.articleCriteria,
            eanNumbers: newProduct.eanNumbers,
            oemNumbers: newProduct.oemNumbers,
            imagePath: newProduct.imagePath
        };
    
        console.log("Product object before submission:", product_object); // Debug: Check product object
    
        // Ensure mandatory fields are filled
        if (product_object.articleNumber && product_object.productName && product_object.supplierId) {
            setFormError(false);
            try {
                const response = await restApi.post("/services/product/create", product_object);
                console.log("API Response:", response); // Debug: Log API response
    
                if (response.data.articleNumber) {
                    console.log("Product added successfully");
                    setNewProduct({
                        articleNumber: '',
                        productName: '',
                        supplierId: '',
                        supplierBrand: '',
                        articleCriteria: '',
                        eanNumbers: '',
                        oemNumbers: '',
                        imagePath: ''
                    });
                    setFormResponse("Produs adaugat cu succes!");
                } else {
                    console.log("Failed to add product");
                    setFormResponse("Ceva nu a mers. Incearca din nou.");
                }
            } catch (error) {
                console.error("Error:", error.response?.data || error.message);
                setFormResponse(error.response?.data || 'Eroare la crearea produsului.');
            }
        } else {
            console.log("Form validation failed");
            setFormError(true);
        }
    };

    const onFileChangeHandler = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        await restApi.post("/services/providers/upload", formData).then(res => {
            console.log(res.data);
            setUploadProvidersResult(res.data);
            // TODO populate message shown after processing
        }).catch(error => {
            setUploadProvidersResult("Ceva nu a functionat. Contacteaza echipa IT");
            // TODO populate error message shown after processing
        })
    };

    // Menu component
    const Menu = ({ menu }) => (
        <ul>
            <li>{menu.parentMenu}</li>
            {menu.subMenus.map(submenu => (
                <SubMenu key={submenu.parentMenu} submenu={submenu} />
            ))}
        </ul>
    );
    
    // SubMenu component (recursive)
    const SubMenu = ({ submenu }) => (
        <ul>
        <li>{submenu.parentMenu}</li>
        {submenu.subMenus.map(submenu => (
            <SubMenu key={submenu.parentMenu} submenu={submenu} />
        ))}
        </ul>
    );

    return (
        <div className="admin">
            <nav className="navbar navbar-expand-lg navbar-light">
            
                <div className="row justify-content-center col-md-4">
                    <h3>Management Produse</h3>
                </div>
                
                <div className="row justify-content-center col-md-4" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <button className="nav-link" onClick={() => manageProducts()}>Produse</button>
                        </li> 
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageUsers()}>Utilizatori</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageOrders()}>Comenzi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageInvoices()}>Facturi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
            </nav>

            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="row justify-content-center col-md-5" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewAddProduct()}>Adauga Produs</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewModifyProduct()}>Modifica Produs</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewExchange()}>Curs Euro</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewPrices()}>Preturi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewProviders()}>Incarca Furnizori</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewManualInvoice()}>Factura Manuala</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => viewUniversalMenu()}>Meniu Universal</button>
                        </li>
                    </ul>
                </div>
            </nav>

            {/* ADAUGA PRODUS */}
            {isLoading === false && manageOption.displayAddProduct === true && 
                <>
                <div className="container">
                    <h4>Adauga un nou produs</h4>
                    <div>
                        <input type="text"
                            className="form-control"
                            placeholder="ArticleNumber"
                            name="articleNumber"
                            value={newProduct.articleNumber}
                            onChange={updateNewProduct}
                        />
                        <input type="number"
                            className="form-control"
                            placeholder="SupplierId"
                            name="supplierId"
                            value={newProduct.supplierId}
                            onChange={updateNewProduct}
                        />
                        <input type="text"
                            className="form-control"
                            placeholder="ProductName"
                            name="productName"
                            value={newProduct.productName}
                            onChange={updateNewProduct}
                        />
                        <input type="text"
                            className="form-control"
                            placeholder="SupplierBrand"
                            name="supplierBrand"
                            value={newProduct.supplierBrand}
                            onChange={updateNewProduct}
                        />
                        <input type="text"
                            className="form-control"
                            placeholder="ArticleCriteria"
                            name="articleCriteria"
                            value={newProduct.articleCriteria}
                            onChange={updateNewProduct}
                        />
                        <input type="text"
                            className="form-control"
                            placeholder="EanNumbers"
                            name="eanNumbers"
                            value={newProduct.eanNumbers}
                            onChange={updateNewProduct}
                        />
                        <p>De forma: BRAND: OENUMBER;BRAND2: OENUMBER2;BRAND3: OENUMBER3</p>
                        <input type="text"
                            className="form-control"
                            placeholder="OemNumbers"
                            name="oemNumbers"
                            value={newProduct.oemNumbers}
                            onChange={updateNewProduct}
                        />
                    </div>
                    <div>
                        <div {...getRootProps({ className: 'dropzone', style: { border: '2px dashed #007bff', padding: '20px', textAlign: 'center', cursor: 'pointer', backgroundColor: isDragActive ? '#e3f7ff' : '#f9f9f9' } })}>
                            <input {...getInputProps()} />
                            <p>{isDragActive ? "Drop the files here..." : "Drag 'n' drop an image here, or click to select one"}</p>
                        </div>
                        {selectedFile && (
                            <div style={{ marginTop: '10px' }}>
                                <img src={`/media/img/${newProduct.imagePath}`} alt={`Selected file: ${selectedFile.name}`} style={{ maxWidth: '100%', height: 'auto' }} />
                                <button className="btn btn-danger" onClick={handleRemoveImage}>Remove Image</button>
                            </div>
                        )}
                        <button className="btn btn-secondary" onClick={() => handleUploadImage('create')} disabled={!selectedFile}>Upload Image</button>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-lg btn-primary btn-block" onClick={handleFormSubmit}>
                            Adauga Produs
                        </button>
                        {formError === true && !formResponse &&
                            <p className="white">Toate campurile sunt obligatorii</p>
                        }
                        {
                            formError === false && formResponse &&
                            <p className="white">{formResponse}</p>
                        }
                    </div>
                </div>
                </>
            }

            {/* MODIFICA PRODUS */}
            {manageOption.displayModifyProduct === true && 
                <>
                <div className="container modify-product">
                    <div className="row nav">
                        <h4>Modifica Produs</h4>
                        <SearchBar {...props} isAdminBar={true} />
                    </div>
                    {isLoading === true && 
                        <ReactSpinner type="border" color="primary" size="2" />
                    }

                    {props.products !== undefined && props.products.length > 0 && 
                        <>
                            {props.products.map((product) => (
                                <>
                                <h4>PRODUS</h4>
                                <table className="table product-management">
                                    <thead>
                                        <tr>
                                            <th scope="col">ArticleNr</th>
                                            <th scope="col">ProductName</th>
                                            <th scope="col">SupplierBrand</th>
                                            <th scope="col">Stoc BG1</th>
                                            <th scope="col">Stoc BG2</th>
                                            <th scope="col">Pret Final (LEI)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                            <tr>
                                                <td>{product.articleNumber}</td>
                                                <td>{product.productName}</td>
                                                <td>{product.supplierBrand}</td>
                                                <td>{product.stockBG1}</td>
                                                <td>{product.stockBG2}</td>
                                                {product.price !== null && 
                                                    <><td>{product.price.toFixed(2)}</td></>
                                                }
                                                {product.price === null && 
                                                <><td></td></>}
                                                <td>
                                                    <button type="button" className="btn btn-success" onClick={() => handleEditProduct(product)}>Editeaza produs</button>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                                {product.providers != undefined && product.providers.length > 0 &&
                                    <>
                                        <h4>Furnizori</h4>
                                        <table className="table product-management">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nume Furnizor</th>
                                                    <th scope="col">Pret achizitie (EUR)</th>
                                                    <th scope="col">Pret vanzare (EUR)</th>
                                                    <th scope="col">Stoc</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            {product.providers.map((provider) => (
                                                <>
                                                {provider.id !== null && provider.id !== undefined && 
                                                    <tr>
                                                        <td>{provider.name}</td>
                                                        <td>{provider.acquisitionPrice}</td>
                                                        <td>{provider.sellingPrice}</td>
                                                        <td>{provider.stock}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-success" onClick={() => handleEditProvider(provider)}>Editeaza Furnizor</button>
                                                        </td>
                                                    </tr>
                                                }
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </>
                                }
                                </>
                            ))}
                        </>
                    }
                </div>
                </>
            }

            {/* CURS VALUTAR */}
            {isLoading === false && manageOption.displayExchange === true && 
                <>
                <div className="container exchange">
                    <input type="number" step="any" placeholder={exchange.value} onChange={(e) => handleEditExchange(e)} />
                    <button type="button" className="btn btn-success" onClick={() => editExchange()}>Salveaza curs euro</button>
                </div>
                </>
            }

            {/* Facturi Manual */}
            {isLoading === false && manageOption.displayManualInvoice === true && 
                <>
                <div className="container manualInvoice">
                    <input type="text" step="any" onChange={(e) => handleManualInvoice(e)} />
                    <button type="button" className="btn btn-success" onClick={() => generateManualInvoice()}>Genereaza Factura</button>
                </div>
                </>
            }

            {/* Preturi */}
            {isLoading === false && manageOption.viewPrices === true && 
                <>
                <div className="container business-increase">
                    <input type="text" step="any" placeholder="Nume brand" onChange={(e) => handleGeneratePricesStocksForBrand(e)} />
                    <button type="button" className="btn btn-success" onClick={() => generatePricesStocksForBrand()}>Genereaza preturi</button>
                    <button type="button" className="btn btn-success" onClick={() => generatePricesStocksForAllBrands()}>Genereaza preturi pentru branduri</button>
                </div>
                </>
            }

            {/* Furnizori */}
            {isLoading === false && manageOption.displayUploadProviders === true &&
                <>
                <div className="container">
                    {uploadProvidersResult !== null && uploadProvidersResult !== undefined && uploadProvidersResult.length > 0 ?
                        <>  
                            {uploadProvidersResult.map((result) => (
                                <p>
                                    {result}
                                </p>
                            ))}
                        </>
                        :
                        <>
                            <h4>Incarca Furnizori</h4>
                            <label>Incarca documentul excel</label>
                            <input type="file" className="form-control" name="file" onChange={(e) => onFileChangeHandler(e)}/>
                        </>
                    }
                    
                </div>
                </>
            }

            {/* Furnizori */}
            {isLoading === false && manageOption.displayUniversalMenu === true &&
                <>
                <div className="container products">
                    {menuHierarchy.map(menu => (
                        <Menu key={menu.parentMenu} menu={menu} />
                    ))}
                </div>
                </>
            }

            <Modal show={showEditProduct} onHide={close} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Editare Produs</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflow-y': 'auto'}}>
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">ArticleNr</th>
                            <th scope="col">ProductName</th>
                            <th scope="col">SupplierBrand</th>
                            {selectedEditProduct.priceBG !== null && 
                                <th scope="col">Pret BG</th>}
                            <th scope="col">Adaos</th>
                            <th scope="col">Pret final</th>
                            <th scope="col">Stoc BG1</th>
                            <th scope="col">Stoc BG2</th>
                            <th scope="col">Raft</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{selectedEditProduct.articleNumber}</td>
                                <td>{selectedEditProduct.productName}</td>
                                <td>{selectedEditProduct.supplierBrand}</td>
                                {selectedEditProduct.priceBG !== null &&
                                    <td>{selectedEditProduct.priceBG}</td>
                                }
                                <td><input type="number" placeholder={selectedEditProduct.addition} onChange={(e) => handleProductAdditionChange(e)} /></td>
                                <td>{selectedEditProduct.price}</td>
                                <td>{selectedEditProduct.stockBG1}</td>
                                <td>{selectedEditProduct.stockBG2}</td>
                                <td><input type="text" placeholder={selectedEditProduct.shelf} onChange={(e) => handleProductShelfChange(e)} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <h6>Cross References</h6>
                        {selectedEditProduct.additionalCrossReferences && selectedEditProduct.additionalCrossReferences.map((additionalCrossReference) => (
                            <div className="row">
                                <p>{additionalCrossReference}</p>
                                <button type="button" className="btn btn-danger" onClick={() => removeCrossReference(additionalCrossReference)}>X</button>
                            </div>
                        ))}
                        <div className="row">
                            <input type="text"
                                        className="form-control"
                                        placeholder="Adauga cross reference"
                                        onChange={handleNewAdditionalCrossReference}
                                    />
                            <button className="btn btn-lg btn-primary btn-block" onClick={addCrossReference}>Adauga</button>
                        </div>
                    </div>
                    <div>
                        <h6>Meniu</h6>
                        <div className="row">
                            <input type="text"
                                className="form-control"
                                placeholder={selectedEditProduct.menu && selectedEditProduct.menu.id ? selectedEditProduct.menu.name : "Adauga Meniu"}
                                onChange={handleAssignMenuForProduct}
                            />
                            <button className="btn btn-lg btn-primary btn-block" onClick={assignMenuForProduct}>Actualizeaza</button>
                        </div>
                    </div>
                    <div>
                    <div>
                        <h6>Imagine</h6>
                        {selectedEditProduct.media !== null && selectedEditProduct.media !== undefined && selectedEditProduct.media.source !== '' && (
                            <div style={{ marginBottom: '10px' }}>
                                <img src={`/media/img/${selectedEditProduct.media.source}`} alt="Current Product Image" style={{ maxWidth: '100%', height: 'auto' }} />
                                <button className="btn btn-danger" onClick={handleRemoveImage}>Remove Image</button>
                            </div>
                        )}
                        {(selectedEditProduct.media === null || selectedEditProduct.media === undefined || selectedEditProduct.media.source === '') && (
                            <div {...getRootProps({ className: 'dropzone', style: { border: '2px dashed #007bff', padding: '20px', textAlign: 'center', cursor: 'pointer', backgroundColor: isDragActive ? '#e3f7ff' : '#f9f9f9' } })}>
                                <input {...getInputProps()} disabled={!!selectedEditProduct.imagePath} />
                                <p>{isDragActive ? "Drop the files here..." : "Drag 'n' drop an image here, or click to select one"}</p>
                            </div>
                        )}
                        {selectedFile && (selectedEditProduct.media === null || selectedEditProduct.media === undefined || selectedEditProduct.media.source === '') && (
                            <div style={{ marginTop: '10px' }}>
                                <p>Selected file: {selectedFile.name}</p>
                                <button className="btn btn-danger" onClick={handleRemoveImage}>Remove Image</button>
                            </div>
                        )}
                        <button className="btn btn-secondary" onClick={() => handleUploadImage('edit')} disabled={!selectedFile || !!selectedEditProduct.imagePath}>Upload Image</button>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" onClick={editProduct}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditProvider} onHide={close} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Editare Furnizor</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflow-y': 'auto'}}>
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">Nume Furnizor</th>
                            <th scope="col">Pret achizitie</th>
                            <th scope="col">Pret vanzare</th>
                            <th scope="col">Stoc</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{selectedEditProvider.name}</td>
                                <td><input type="number" placeholder={selectedEditProvider.acquisitionPrice} onChange={(e) => handleEditProviderChange('acquisitionPrice', e)} /></td>
                                <td><input type="number" placeholder={selectedEditProvider.sellingPrice} onChange={(e) => handleEditProviderChange('sellingPrice', e)} /></td>
                                <td><input type="number" placeholder={selectedEditProvider.stock} onChange={(e) => handleEditProviderChange('stock', e)} /></td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" onClick={editProvider}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    );
}

export default withRouter(ProductManagement);