import MenuComponent from "./MenuComponent";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

    return (
      <ul className={`dropdown-component ${dropdownClass} ${dropdown ? "show" : ""}`}>
        {submenus.map((submenu, index) => (
          <MenuComponent items={submenu} key={index} depthLevel={depthLevel} />
        ))}
      </ul>
    );
  };
  
  export default Dropdown;