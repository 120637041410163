import axios from 'axios';

const restApi = axios.create({
    baseURL: 'https://asparts.ro'
    //baseURL: 'http://localhost:8080'
})

restApi.interceptors.request.use(
    function(config) {
      const token = localStorage.getItem("authorization");
      if (token !== null) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(err) {
      return Promise.reject(err);
    }
  );

export default restApi;