import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import ReactSpinner from 'react-bootstrap-spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const PAGE_USERS = 'users';
const PAGE_ORDERS = 'orders';
const PAGE_PRODUCTS = 'adminproducts';
const PAGE_INVOICES = 'invoices';

function Invoices(props) {

    const isLoading = props.isLoading;
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [filteredSocieties, setFilteredSocieties] = useState([]);
    const [selectedSociety, setSelectedSociety] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    if (props.invoices) {
        // to return list with all societies

        props.invoices.map(invoice => {
            if (filteredSocieties.indexOf(invoice.societyName) === -1) {
                filteredSocieties.push(invoice.societyName);
            }
        });
    }

    const handleSelectSociety = (societyName) => {
        setSelectedSociety(societyName);
    }

    const filterInvoices = () => {
        props.invoices.map(function(invoice) {
            var sem = 0;
            if (invoice === props.invoices[0]) {
                setFilteredInvoices([]);
            }
            if (startDate !== undefined && new Date(invoice.processedDate) < new Date(startDate)) {
                sem = 1;
            }
            if (endDate !== undefined && new Date(invoice.processedDate) > new Date(endDate)) {
                sem = 1;
            }
            if (selectedSociety !== undefined && invoice.societyName !== selectedSociety) {
                sem = 1;
            }
            if (sem === 0) {
                setFilteredInvoices(filteredInvoices => [...filteredInvoices, invoice]);
            }
        })
    }
    
    const navigateTo = (page) => {
        console.log(page);
        props.history.push(page);
    }

    const handleLogout = () => {
        props.handleLogout();
        navigateTo('/');
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "ADMIN") {
        navigateTo('/');
    }

    const manageUsers = () => {
        props.getUsers();
        navigateTo(PAGE_USERS);
    }

    const manageOrders = () => {
        navigateTo(PAGE_ORDERS);
    }

    const manageProducts = () => {
        navigateTo(PAGE_PRODUCTS);
    }

    const manageInvoices = () => {
        props.getInvoices();
        navigateTo(PAGE_INVOICES);
    }

    return (
        <div className="admin">
            <nav className="navbar navbar-expand-lg navbar-light">
            
                <div className="row justify-content-center col-md-4">
                    <h3>Facturi</h3>
                </div>
                
                <div className="row justify-content-center col-md-4" id="navbarSupportedContent">

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageProducts()}>Produse</button>
                        </li>
                        <li className="nav-item active">
                            <button className="nav-link" onClick={() => manageUsers()}>Utilizatori</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageOrders()}>Comenzi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" oncClick={() => manageInvoices()}>Facturi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
            </nav>
            {isLoading === false && 
            <div className="list-group-item list-group-item-action flex-column align-items-start">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <Dropdown>
                                    <DropdownButton
                                    alignRight
                                    title={selectedSociety}
                                    onSelect={handleSelectSociety}>
                                        {filteredSocieties.map((society) =>
                                            <Dropdown.Item eventKey={society}>{society}</Dropdown.Item>
                                        )}
                                    </DropdownButton>
                                </Dropdown>
                            </th>
                            <th scope="col">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            </th>
                            <th scope="col">
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                            </th>
                            <th scope="col">
                                <button className="nav-link" onClick={() => filterInvoices()}>Filtreaza</button>
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                        <th scope="col">Nume Societate</th>
                        <th scope="col">Data procesare</th>
                        <th scope="col">Numar factura</th>
                        <th scope="col">Factura</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredInvoices.map((invoice) => (
                        <tr>
                            <td>{invoice.societyName}</td>
                            <td>{invoice.processedDate}</td>
                            <td>{invoice.invoicePdfName}</td>
                            <td><a href={invoice.url} target="_blank">Acceseaza</a></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}

export default withRouter(Invoices);