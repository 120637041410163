import React, { useState } from 'react';
import restApi from '../../../api/Source';
import Footer from "../../../components/user/Footer/Footer";
import Header from "../../../components/user/Header/Header";
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import './Cart.css';

// https://www.youtube.com/watch?v=GW9DCk-qZWk

function Cart(props) {

    const [description, setDescription] = useState('');
    const [displayModal, setDisplayModal] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');
    const cart = props.cart;
    
    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }

    const handleHideModal = () => {
        setOrderStatus('');
        setDisplayModal(false);
    }

    const handleDisplayModal = (orderStatus) => {
        setOrderStatus(orderStatus);
        setDisplayModal(true);
    }

    const navigateTo = (page) => {
        props.history.push(page);
    }

    const removeFromCart = (removedProduct) => {
        let updatedCart = cart.filter((product) => product !== removedProduct);
        props.setCart(
            updatedCart
        );
        // save to localStorage
        let stringCart = JSON.stringify(updatedCart);
        localStorage.setItem("cart", stringCart);
    }

    const getTotalSum = () => {
        return cart.reduce((sum, { price, quantity }) => sum + price * quantity, 0);
    }

    const decreaseQuantity = (product) => {
        if (product.quantity === 1) {
            removeFromCart(product);
        } else {
            let updatedCart = [...cart];
            
            updatedCart.find(
                (item) => product.articleNumber === item.articleNumber && 
                            product.supplierId === item.supplierId
                ).quantity--;

            props.setCart(updatedCart);

            // save to localStorage
            let stringCart = JSON.stringify(updatedCart);
            localStorage.setItem("cart", stringCart);
        }
    }

    const increaseQuantity = (product) => {
        let updatedCart = [...cart];

        updatedCart.find(
            (item) => product.articleNumber === item.articleNumber && 
                        product.supplierId === item.supplierId
            ).quantity++;

        props.setCart(updatedCart);

        // save to localStorage
        let stringCart = JSON.stringify(updatedCart);
        localStorage.setItem("cart", stringCart);
    }

    const handleDescription = (e) => {
        const text = e.target.value;
        setDescription(text);
    }

    const handleSubmitOrder = async() => {
        const request = {
            user: {"username": localStorage.getItem("user"), "societyName": localStorage.getItem("societyName")},
            description: description,
            products: cart
        }
        
        await restApi.post("/services/submit", request).then(res => {
            handleDisplayModal("Comanda plasata cu succes!");
        }).catch(error => {
            handleDisplayModal("Ceva nu a mers. Incearca din nou!");
        });
        props.setCart([]);
        localStorage.removeItem("cart");
    }

    return (
        <div className="cart page-container">
            <div id="content-wrap">
                <Header {...props} />

                <div className="row details justify-content-center">
                    <div className="row container">
                        <div className="center col-md-6 items">
                            <div>
                            {cart.map((product, idx) => (
                                <div class="list-group">
                                    <div className="row list-group-item list-group-item-action align-items-start">
                                        <div className="col-md-6">
                                            <h6>{product.articleNumber}</h6>
                                            <h6>{product.productName}</h6>
                                            <p>{product.supplierBrand}</p>
                                        </div>
                                        <div className=" col-md-6">
                                            <div>
                                                <p className="no-margins">Cantitate: <span><b>{product.quantity}</b></span></p>
                                            </div>
                                            <div>
                                                <button type="button" class="btn btn-info btn-sm" onClick={() => decreaseQuantity(product)}>-</button>
                                                <button type="button" class="btn btn-info btn-sm" onClick={() => increaseQuantity(product)}>+</button>
                                            </div>
                                            <div>
                                                <p><span><b>Pret cu TVA:</b></span> {(product.price * product.quantity).toFixed(2)} RON</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => removeFromCart(product)}>Elimina</button>
                                </div>
                            ))}
                            </div>
                        </div>
                        
                        {cart.length > 0 && 
                            <div className="col-md-6 summary"> 
                                <div className="container">
                                    <div className="center">
                                        <p>Cost Total: {getTotalSum().toFixed(2)} RON</p>
                                        <p>Detalii suplimentare</p>
                                        <textarea id="noter-text-area" name="textarea" 
                                            value={description} 
                                            onChange={handleDescription} />
                                    </div>
                                    <button type="button" class="btn btn-primary order" onClick={() => handleSubmitOrder()}>Trimite comanda</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <Modal show={displayModal} onHide={handleHideModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Comanda Dumneavoastra</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{orderStatus}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={handleHideModal}>
                        OK
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(Cart)