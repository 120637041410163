import { useState, useEffect, useRef } from "react";
import Dropdown from './Dropdown';

const MenuComponent = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
     if (dropdown && ref.current && !ref.current.contains(event.target)) {
      setDropdown(false);
     }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
     // Cleanup the event listener
     document.removeEventListener("mousedown", handler);
     document.removeEventListener("touchstart", handler);
    };
   }, [dropdown]);

  return (
    <li className="menu-items" ref={ref}>
      {items.subMenus.length > 0 ? (
        <>
          <button type="button" aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}>
            {items.parentMenu}{' '}
            {depthLevel > 0 ? <span>&raquo;</span> : <span>&rsaquo;</span>}
          </button>
          <Dropdown submenus={items.subMenus} dropdown={dropdown} depthLevel={depthLevel} />
        </>
      ) : (
        <a href={'/menu?menuName=' + items.parentMenu}>{items.parentMenu}</a>
      )}
    </li>
  );
};

export default MenuComponent;