import React, {useState, useEffect} from 'react';

import restApi from '../../../api/Source';
import { withRouter } from 'react-router-dom';
import ReactSpinner from 'react-bootstrap-spinner';
import Header from "../../../components/user/Header/Header";
import MenuComponent from "../../../components/user/MenuComponent/MenuComponent";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


import './Menu.css';

function Menu(props) {

    // TODO Claudiu if path has a category (as query param best), get the specific products and render them
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [products, setProducts] = useState([]);
    const [commercialAdaos, setCommercialAdaos] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [menuHierarchy, setMenuHierarchy] = useState();
    // for menu
    const depthLevel = 0;

    const navigateTo = (page) => {
        props.history.push(page);
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (localStorage.getItem("commercialAdaos") !== null && localStorage.getItem("commercialAdaos") !== undefined) {
            setCommercialAdaos(localStorage.getItem("commercialAdaos"));
        }
        setLoading(true);
        restApi.get("/services/menu/hierarchy").then(menuHierarchyResponse => {
            setMenuHierarchy(menuHierarchyResponse.data);
            console.log(menuHierarchy);
        }).catch(error => {
            setLoading(false);
            if (error.response.status === 401) {
                navigateTo('/');
            }
        }).then(() => {
            if (searchParams.get("menuName") !== null && searchParams.get("menuName") !== undefined && searchParams.get("menuName") !== '') {
                restApi.get("/services/menu?menuName=" + searchParams.get("menuName")).then(productsResponse => {
                    setProducts(productsResponse.data);
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    if (error.response.status === 401) {
                        navigateTo('/');
                    }
                })
            } else {
                setLoading(false);
            }
        });
    }, []);

    const addToCart = (product) => {
        props.addToCart(product);
    }
    
    return (
        <div className="page-container menu-page">
            <div id="content-wrap">
                <Header {...props} />
            </div>
            {isLoading === true && 
                <ReactSpinner type="border" color="primary" size="2" />
            }
            {isLoading === false && 
                <div className="container products">
                    <div className="menus">
                        <ul>
                            {menuHierarchy.map((menu, index) => (
                                <MenuComponent items={menu} key={index} depthLevel={depthLevel} />
                            ))}
                        </ul>
                    </div>
                    {products !== null && products !== undefined && products.length > 0 && 
                        <div className="items">
                        {products.map((product) => (<>
                            <div className="list-group-item item">
                                <a className="view overlay" href={'/product/' + encodeURIComponent(product.supplierId) + '/' + encodeURIComponent(product.articleNumber)}>
                                    <div className="item-details high-resolution"> 
                                        {product.media && product.media.type === "1" ?
                                            <div className="product-img">      
                                                <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                            </div>
                                            : <div className="product-img"><img src='' className="img-responsive" alt="" /></div>
                                        }
                                        <div className="details">
                                            <h6>{product.productName} {product.supplierBrand}</h6>
                                            <p>{product.articleNumber}</p>
                                        </div>
                                        <div className="price">
                                            {commercialAdaos > 0 && 
                                                <p>Pret cu TVA: <span><b>{(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</b></span></p>
                                            }
                                            {commercialAdaos == 0 && 
                                                <p>Pret cu TVA: <span><b>{product.price.toFixed(2)} RON</b></span></p>
                                            }
                                            {product.stockBG1 !== null && product.stockBG1 > 0 && <p>Stoc BG1: <span><b>{product.stockBG1}</b></span></p>}
                                            {product.stockBG2 !== null && product.stockBG2 > 0 && <p>Stoc BG2: <span><b>{product.stockBG2}</b></span></p>}
                                            {product.stockProviders !== null && product.stockProviders > 0 && <p>Stoc RO: <span><b>{product.stockProviders}</b></span></p>}
                                        </div>
                                    </div>
                                    <div className="item-details low-resolution"> 
                                        <div className="product-img-details">
                                            {product.media && product.media.type === "1" ?
                                                    <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                                    : <img src='' className="img-responsive" alt="" />
                                            }
                                            <h6>{product.productName} {product.supplierBrand}</h6>
                                            <p>{product.articleNumber}</p>
                                        </div>
                                        <div className="price">
                                            {commercialAdaos > 0 && 
                                                <p>Pret cu TVA: <span><b>{(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</b></span></p>
                                            }
                                            {commercialAdaos == 0 && 
                                                <p>Pret cu TVA: <span><b>{product.price.toFixed(2)} RON</b></span></p>
                                            }
                                            {product.stockBG1 !== null && product.stockBG1 > 0 && <p>Stoc BG1: <span><b>{product.stockBG1}</b></span></p>}
                                            {product.stockBG2 !== null && product.stockBG2 > 0 && <p>Stoc BG2: <span><b>{product.stockBG2}</b></span></p>}
                                            {product.stockProviders !== null && product.stockProviders > 0 && <p>Stoc RO: <span><b>{product.stockProviders}</b></span></p>}
                                        </div>
                                    </div>
                                </a>
                                <button type="button" className="btn btn-info cart-btn" onClick={() => addToCart(product)}><FontAwesomeIcon icon={faShoppingCart} /></button>
                            </div>
                        </>
                        ))}
                    </div>
                    }   
                </div>
            }
        </div>
    );
}

export default withRouter(Menu)