import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import ReactSpinner from 'react-bootstrap-spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import restApi from '../../../api/Source';
// https://reactdatepicker.com/#example-date-range-with-disabled-navigation-shown
import DatePicker from 'react-datepicker';

import './Orders.css';


const PAGE_USERS = 'users';
const PAGE_ORDERS = 'orders';
const PAGE_PRODUCTS = 'adminproducts';
const PAGE_INVOICES = 'invoices';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

function Orders(props) {

    // ACORDION
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const [orders, setOrders] = useState([]);

    const [quantityError, setQuantityError] = useState(false);
    // modals
    const [showSend, setShowSend] = useState(false);
    const [showProcess, setShowProcess] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showReturn, setShowReturn] = useState(false);
    // current selected order for edit/process
    const [selectedOrder, setSelectedOrder] = useState({});
    const [selectedOrderCopy, setSelectedOrderCopy] = useState({});
    // status of the orders selected
    const [orderStatus, setOrderStatus] = useState('PENDING');
    // used for edit selectedPriceProvider in edit Modal to re-render without updating state
    const [randomKey, setRandomKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    useEffect(() => {
        window.scroll(0, 0);
        // TODO make sure it s in gmt + 2 format
        console.log(new Date());

        getOrders(orderStatus, null);
    }, []);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }


    const getOrders = async(orderStatus, filters) => {
        setIsLoading(true);
        if (filters === null) {
            const endDate = formatDate(new Date());
            // by default get orders from last 3 months
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 3);
            setStartDate(startDate);
            setEndDate(new Date());
            
            filters = {
                startDate: formatDate(startDate), endDate: endDate
            };
        }
        
        await restApi.post("/services/orders?orderStatus=" + orderStatus, filters).then(orders => {
            setOrders(orders.data);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setError(error);
        })
    }

    const handleSearchByFilters = () => {
        const filters = {
            startDate: formatDate(startDate), endDate: formatDate(endDate)
        };
        getOrders(orderStatus, filters);
    }

    const handleClose = () => {
        getOrders(orderStatus, null);
        setSelectedOrder({}); 
        setSelectedOrderCopy({});
        setQuantityError(false); 
        setShowSend(false);  
        setShowProcess(false);
        setShowEdit(false);
        setShowReturn(false);
    }

    // showSend popup to process order
    const sendOrder = (order) => {
        setSelectedOrder(order);
        setSelectedOrderCopy(JSON.parse(JSON.stringify(order)));
        setShowSend(true);
    }

    // showProcess popup to process order
    const processOrder = (order) => {
        setSelectedOrder(order);
        setSelectedOrderCopy(JSON.parse(JSON.stringify(order)));
        setShowProcess(true);
    }

    const editOrder = (order) => {
        setSelectedOrder(order);
        setShowEdit(true);
    }

    const returnOrder = (order) => {
        setSelectedOrder(order);
        setShowReturn(true);
    }

    const deliverOrder = (product) => {
        handleDeliverOrder(product);
    }

    const cancelOrder = (product) => {
        handleCancelOrder(product);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    const navigateTo = (page) => {
        console.log(page);
        props.history.push(page);
    }

    const handleLogout = () => {
        props.handleLogout();
        navigateTo('/');
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "ADMIN") {
        navigateTo('/');
    }

    const manageUsers = () => {
        props.getUsers();
        navigateTo(PAGE_USERS);
    }

    const manageOrders = () => {
        getOrders("PENDING", null);
        setOrderStatus("PENDING");
        navigateTo(PAGE_ORDERS);
    }

    const manageProducts = () => {
        navigateTo(PAGE_PRODUCTS);
    }

    const manageInvoices = () => {
        props.getInvoices();
        navigateTo(PAGE_INVOICES);
    }

    const handleOrderStatus = (e) => {
        getOrders(e, null);
        setOrderStatus(e);
    }

    const handleOrderProductNameChange = (e, product) => {
        if (e.target.value !== undefined) {
            product.productName = e.target.value;
        }
    }

    const handleOrderProductNameChangeEdit = (e, product) => {
        if (e.target.value !== undefined) {
            product.productName = e.target.value;
        }
    }

    // TODO here, whenever i showProcess the error message or hide it, I have to check all the quantities from each product
    const handleOrderQuantityChange = (e, product) => {
        // product from main order, not the updated one
        const unchangedProduct = selectedOrderCopy.products.find(selectedProduct => selectedProduct.articleNumber === product.articleNumber && selectedProduct.supplierId === product.supplierId);
        
        if (e.target.value !== undefined && Number(e.target.value) <= Number(unchangedProduct.quantity)) {
            product.quantity = Number(e.target.value);
            setQuantityError(false);
        } else {
            setQuantityError(true);
            console.log("Quantity not allowed");
        }
    }

    const handleOrderQuantityChangeEdit = (e, product) => {
        
        if (e.target.value !== undefined) {
            product.quantity = Number(e.target.value);
        }
    }

    const handleOrderProviderChangeEdit = (e, product) => {
        if (e.target.value !== undefined) {
            product.selectedProviderPrice = e.target.value;
            setRandomKey(Math.random());
        }
        console.log(product);
    }

    const handleOrderUnitPriceChangeEdit = (e, product) => {
        
        if (e.target.value !== undefined) {
            product.unitPriceNoTVA = e.target.value;
        }
    }

    const handleChangeCheckbox = (e, userOrder) => {
        
        if (userOrder.isCheckedForMerge === undefined) {
            userOrder.isCheckedForMerge = true;
        } else {
            userOrder.isCheckedForMerge = !userOrder.isCheckedForMerge;
        }
        console.log(userOrder);
    }

    const mergeOrders = (order) => {
        handleMergeOrders(order);
    }

    const handleSendOrder = async() => {
        if (quantityError === false) {
            console.log(selectedOrder);
            // submit order
            await restApi.post("/services/order/send", selectedOrder).then(res => {
                console.log(res);
                getOrders("PENDING", null);
                setIsLoading(false);
                setShowSend(false);
            }).catch(error => {
                setIsLoading(false);
                setError(error);
            })
        }
    }

    const handleProcessOrder = async() => {
        if (quantityError === false) {
            console.log(selectedOrder);
            // submit order
            await restApi.post("/services/order/process", selectedOrder).then(res => {
                console.log(res);
                getOrders("SENT", null);
                setIsLoading(false);
                setShowProcess(false);
            }).catch(error => {
                setIsLoading(false);
                setError(error);
            })
        }
    }

    const handleReturnOrder = async() => {
        if (quantityError === false) {
            console.log(selectedOrder);
            await restApi.post("/services/order/return", selectedOrder).then(res => {
                console.log(res);
                getOrders("DELIVERED", null);
                setIsLoading(false);
                setShowReturn(false);
            }).catch(error => {
                setIsLoading(false);
                setError(error);
            })
        }
    }

    const handleEditOrder = async() => {
        await restApi.post("/services/order/edit", selectedOrder).then(res => {
            console.log(res);
            getOrders("PENDING", null);
            setIsLoading(false);
            setShowEdit(false);
        }).catch(error => {
            setIsLoading(false);
            setError(error);
        })
    }

    const handleDeliverOrder = async(product) => {
        await restApi.post("/services/order/deliver", product).then(res => {
            console.log(res);
            getOrders("PROCESSED", null);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setError(error);
        })
    }
    
    const handleCancelOrder = async(product) => {
        await restApi.post("/services/order/cancel", product).then(res => {
            console.log(res);
            getOrders("PENDING", null);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setError(error);
        })
    }  

    const handleMergeOrders = async(orders) => {
        await restApi.post("/services/orders/merge", orders).then(res => {
            console.log(res);
            getOrders("SENT", null);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setError(error);
        })
    }  

    return (
        <div className="admin">
            <nav className="navbar navbar-expand-lg navbar-light">
            
                <div className="row justify-content-center col-md-4">
                    <h3>Comenzi</h3>
                </div>
                
                <div className="row justify-content-center col-md-4" id="navbarSupportedContent">

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageProducts()}>Produse</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageUsers()}>Utilizatori</button>
                        </li>
                        <li className="nav-item active">
                            <button className="nav-link" onClick={() => manageOrders()}>Comenzi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageInvoices()}>Facturi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
            </nav>
            {isLoading === false && 
            <div>
                <Dropdown>
                    <DropdownButton
                    alignRight
                    title={orderStatus}
                    onSelect={handleOrderStatus}>
                        <Dropdown.Item active eventKey="PENDING">Pending</Dropdown.Item>
                        <Dropdown.Item eventKey="SENT">Trimise</Dropdown.Item>
                        <Dropdown.Item eventKey="PROCESSED">Procesate</Dropdown.Item>
                        <Dropdown.Item eventKey="DELIVERED">Livrate</Dropdown.Item>
                        <Dropdown.Item eventKey="RETURNED">Returnate</Dropdown.Item>
                        <Dropdown.Item eventKey="CANCELLED">Anulate</Dropdown.Item>
                    </DropdownButton>
                </Dropdown>

                <div className="orders-filters">
                    <div>
                        <h6>Data de start</h6>
                        <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" maxDate={endDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    
                    <div>
                        <h6>Data de final</h6>
                        <DatePicker selected={endDate} dateFormat="dd/MM/yyyy" minDate={startDate} onChange={(date) => setEndDate(date)} />
                    </div>
                    <div className="apply-button">
                        <button type="button" class="btn btn-success" onClick={handleSearchByFilters}>Aplica Filtre</button>
                    </div>
                </div>

                <div className="list-group-item list-group-item-action flex-column align-items-start">
                    {orders.map((order) => (
                        <Accordion expanded={expanded === (order.user)} onChange={handleChange(order.user)}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                            <Typography className={classes.heading}>{order.societyName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {order.orders.map((userOrder) => (
                                        <div className="orders">
                                            {orderStatus === "SENT" && 
                                                <div>
                                                    <input type="checkbox" onChange={(e) => handleChangeCheckbox(e, userOrder)} />Selecteaza pentru unire
                                                </div>
                                            }
                                            <div>
                                                <h6><b>User:</b> <span>{order.user}</span></h6>
                                                <h6><b>Mentiuni:</b> <span>{userOrder.description}</span></h6>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col">Numar Articol</th>
                                                        <th scope="col">Nume Produs</th>
                                                        <th scope="col">Brand</th>
                                                        <th scope="col">Cantitate</th>
                                                        <th scope="col">Pret (LEI)</th>
                                                        <th scope="col">Furnizor</th>
                                                        <th scope="col">Status</th>
                                                        {(orderStatus !== "PROCESSED" && orderStatus !== "DELIVERED" && orderStatus !== "CANCELLED" && orderStatus !== "RETURNED") &&
                                                            <>
                                                            <th scope="col">Stoc BG1</th>
                                                            <th scope="col">Stoc BG2</th>
                                                            <th scope="col">Stoc AutoPlus</th>
                                                            <th scope="col">Stoc AutoPartner</th>
                                                            </>
                                                        }
                                                        <th scope="col">Data venire comanda</th>
                                                        {orderStatus === "SENT" && 
                                                        <th scope="col">Data trimitere</th>}
                                                        {orderStatus === "SENT" && 
                                                        <th scope="col">APIOrder id</th>}
                                                        {orderStatus === "PROCESSED" && 
                                                        <th scope="col">Data procesare</th>}
                                                        {orderStatus === "DELIVERED" && 
                                                        <th scope="col">Data livrare</th>}
                                                        {orderStatus === "RETURNED" && 
                                                        <th scope="col">Data returnare</th>}
                                                        {orderStatus === "CANCELLED" && 
                                                        <th scope="col">Data anulare</th>}
                                                        {orderStatus !== "PENDING" && orderStatus !== "SENT" && 
                                                        <th scope="col">Factura</th>}
                                                        {orderStatus === "RETURNED" && 
                                                        <th scope="col">Factura retur</th>}
                                                        <th scope="col">Raft</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userOrder.products.map((product) => (
                                                            <tr>
                                                                <td>{product.articleNumber}</td>
                                                                <td>{product.productName}</td>
                                                                <td>{product.supplierBrand}</td>
                                                                <td>{product.quantity}</td>
                                                                <td>{product.price}</td>
                                                                <td>{product.selectedProviderPrice}</td>
                                                                <td>{product.status}</td>
                                                                {(orderStatus !== "PROCESSED" && orderStatus !== "DELIVERED" && orderStatus !== "CANCELLED" && orderStatus !== "RETURNED") &&
                                                                    <>
                                                                    {product.stockBG1 !== null && product.stockBG1 !== undefined ? (
                                                                        <td>{product.stockBG1}</td>
                                                                    ) : (
                                                                        <td>0</td>
                                                                    )}
                                                                    {product.stockBG2 !== null && product.stockBG2 !== undefined ? (
                                                                        <td>{product.stockBG2}</td>
                                                                    ) : (
                                                                        <td>0</td>
                                                                    )}
                                                                    {product.providers !== null && product.providers !== undefined ? (
                                                                        <>
                                                                        <td>{product.providers[0].stock}</td>
                                                                        <td>{product.providers[1].stock}</td>
                                                                        </>) : (
                                                                            <>
                                                                            <td>0</td>
                                                                            <td>0</td>
                                                                            </>
                                                                        )
                                                                    }
                                                                    </>
                                                                }
                                                                <td>{product.submittedDate}</td>
                                                                {orderStatus === "SENT" && 
                                                                <td>{product.sentDate}</td>}
                                                                {orderStatus === "SENT" && 
                                                                <td>{product.orderId}</td>}
                                                                {orderStatus === "PROCESSED" && 
                                                                <td>{product.processedDate}</td>}
                                                                {orderStatus === "DELIVERED" && 
                                                                <td>{product.deliveredDate}</td>}
                                                                {orderStatus === "RETURNED" && 
                                                                <td>{product.returnedDate}</td>}
                                                                {orderStatus === "CANCELLED" && 
                                                                <td>{product.cancelledDate}</td>}
                                                                {orderStatus !== "PENDING" && orderStatus !== "SENT" && 
                                                                <td><a href={product.invoice} target="_blank">Acceseaza</a></td>}
                                                                {orderStatus === "RETURNED" && 
                                                                <td><a href={product.invoiceReturn} target="_blank">Acceseaza</a></td>}
                                                                {product.selectedProviderPrice !== 'BG' ? (
                                                                    <td>{product.shelf}</td>
                                                                ) : (
                                                                    <td></td>
                                                                )}
                                                                <td>
                                                                {orderStatus === "PROCESSED" && 
                                                                    <button type="button" class="btn btn-success" onClick={() => deliverOrder(product)}>Comanda Livrata</button>
                                                                }
                                                                </td>
                                                                <td>
                                                                {(orderStatus === "PENDING" || orderStatus === "SENT") && 
                                                                    <button type="button" class="btn btn-danger" onClick={() => cancelOrder(product)}>Anuleaza Comanda</button>
                                                                }
                                                                </td>
                                                            </tr>
                                                        
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="buttons">
                                            {orderStatus === "PENDING" && 
                                                <>
                                                <button type="button" class="btn btn-warning" onClick={() => editOrder(userOrder)}>Editeaza comanda</button>
                                                <button type="button" class="btn btn-success" onClick={() => sendOrder(userOrder)}>Trimite comanda</button></>                                            }
                                            {orderStatus === "SENT" && 
                                                <><button type="button" class="btn btn-success" onClick={() => processOrder(userOrder)}>Procesare comanda</button></>
                                            }
                                            {orderStatus === "DELIVERED" && 
                                                <button type="button" class="btn btn-warning" onClick={() => returnOrder(userOrder)}>Returnare comanda</button>
                                            }
                                            </div>
                                        </div>
                                    ))}
                                    {orderStatus === "SENT" &&
                                        <>
                                        <div>
                                            <button type="button" class="btn btn-success" onClick={() => mergeOrders(order)}>Uneste comenzile</button>
                                        </div>
                                        </>
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
                <Modal show={showSend} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title>Trimitere Comanda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'overflow-y': 'auto'}}>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Numar Articol</th>
                                <th scope="col">Nume</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Cantitate</th>
                                <th scope="col">Status</th>
                                <th scope="col">Stoc BG1</th>
                                <th scope="col">Stoc BG2</th>
                                <th scope="col">Stoc AutoPlus</th>
                                <th scope="col">Stoc AutoPartner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder.products && selectedOrder.products.map((product) => (
                                    <tr>
                                        <td>{product.articleNumber}</td>
                                        <td><input type="text" placeholder={product.productName} onChange={(e) => handleOrderProductNameChange(e, product)} /></td>
                                        <td>{product.supplierBrand}</td>
                                        <td><input type="text" placeholder={product.quantity} onChange={(e) => handleOrderQuantityChange(e, product)} /></td>
                                        <td>{product.status}</td>
                                        {product.stockBG1 !== null && product.stockBG1 !== undefined ? (
                                            <td>{product.stockBG1}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.stockBG2 !== null && product.stockBG2 !== undefined ? (
                                            <td>{product.stockBG2}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.providers !== null && product.providers !== undefined ? (
                                            <>
                                            <td>{product.providers[0].stock}</td>
                                            <td>{product.providers[1].stock}</td>
                                            </>) : (
                                                <>
                                                <td>0</td>
                                                <td>0</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {quantityError && 
                        <p className="red">*Quantity is greater than the one requested by client!</p>}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSendOrder}>
                        Trimite comanda
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showProcess} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                    <Modal.Title>Procesare Comanda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'overflow-y': 'auto'}}>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Numar Articol</th>
                                <th scope="col">Nume</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Cantitate</th>
                                <th scope="col">Status</th>
                                <th scope="col">Stoc BG1</th>
                                <th scope="col">Stoc BG2</th>
                                <th scope="col">Stoc AutoPlus</th>
                                <th scope="col">Stoc AutoPartner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder.products && selectedOrder.products.map((product) => (
                                    <tr>
                                        <td>{product.articleNumber}</td>
                                        <td><input type="text" placeholder={product.productName} onChange={(e) => handleOrderProductNameChange(e, product)} /></td>
                                        <td>{product.supplierBrand}</td>
                                        <td><input type="text" placeholder={product.quantity} onChange={(e) => handleOrderQuantityChange(e, product)} /></td>
                                        <td>{product.status}</td>
                                        {product.stockBG1 !== null && product.stockBG1 !== undefined ? (
                                            <td>{product.stockBG1}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.stockBG2 !== null && product.stockBG2 !== undefined ? (
                                            <td>{product.stockBG2}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.providers !== null && product.providers !== undefined ? (
                                            <>
                                            <td>{product.providers[0].stock}</td>
                                            <td>{product.providers[1].stock}</td>
                                            </>) : (
                                                <>
                                                <td>0</td>
                                                <td>0</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {quantityError && 
                        <p className="red">*Quantity is greater than the one requested by client!</p>}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleProcessOrder}>
                        Procesare comanda
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showEdit} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                    <Modal.Title>Editare Comanda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'overflow-y': 'auto'}}>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Numar Articol</th>
                                <th scope="col">Nume</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Cantitate</th>
                                <th scope="col">Status</th>
                                <th scope="col">Furnizor</th>
                                <th scope="col">Pret unitar (fara TVA)</th>
                                <th scope="col">Stoc BG1</th>
                                <th scope="col">Stoc BG2</th>
                                <th scope="col">Stoc AutoPlus</th>
                                <th scope="col">Stoc AutoPartner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder.products && selectedOrder.products.map((product) => (
                                    <tr>
                                        <td>{product.articleNumber}</td>
                                        <td><input type="text" placeholder={product.productName} onChange={(e) => handleOrderProductNameChangeEdit(e, product)} /></td>
                                        <td>{product.supplierBrand}</td>
                                        <td><input type="text" placeholder={product.quantity} onChange={(e) => handleOrderQuantityChangeEdit(e, product)} /></td>
                                        <td>{product.status}</td>
                                        <td>
                                            <select key={randomKey} value={product.selectedProviderPrice} onChange={(e) => handleOrderProviderChangeEdit(e, product)} className="form-control">
                                                {product.selectedProviderPrice === "AUTO_PLUS" ? (
                                                    <option value="AUTO_PLUS" selected>Auto Plus</option>
                                                ) : (
                                                    <option value="AUTO_PLUS">Auto Plus</option>
                                                )
                                                }
                                                {product.selectedProviderPrice === "AUTO_PARTNER" ? (
                                                    <option value="AUTO_PARTNER" selected>Auto Partner</option>
                                                ) : (
                                                    <option value="AUTO_PARTNER">Auto Partner</option>
                                                )
                                                }
                                                {product.selectedProviderPrice === "BG" ? (
                                                    <option value="BG" selected>BG</option>
                                                ) : (
                                                    <option value="BG">BG</option>
                                                )
                                                }
                                            </select>
                                        </td>
                                        <td><input type="text" placeholder={product.unitPriceNoTVA} onChange={(e) => handleOrderUnitPriceChangeEdit(e, product)} /></td>
                                        {product.stockBG1 !== null && product.stockBG1 !== undefined ? (
                                            <td>{product.stockBG1}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.stockBG2 !== null && product.stockBG2 !== undefined ? (
                                            <td>{product.stockBG2}</td>
                                            ) : (
                                            <td>0</td>
                                        )}
                                        {product.providers !== null && product.providers !== undefined ? (
                                            <>
                                            <td>{product.providers[0].stock}</td>
                                            <td>{product.providers[1].stock}</td>
                                            </>) : (
                                                <>
                                                <td>0</td>
                                                <td>0</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditOrder}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showReturn} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title>Returnare Comanda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'overflow-y': 'auto'}}>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">Numar Articol</th>
                                <th scope="col">Nume</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Cantitate</th>
                                <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder.products && selectedOrder.products.map((product) => (
                                    <tr>
                                        <td>{product.articleNumber}</td>
                                        <td>{product.productName}</td>
                                        <td>{product.supplierBrand}</td>
                                        <td><input type="text" placeholder={product.quantity} onChange={(e) => handleOrderQuantityChangeEdit(e, product)} /></td>
                                        <td>{product.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleReturnOrder}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>}
        </div>
    );
}

export default withRouter(Orders);