import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Footer from "../../../components/user/Footer/Footer";
import Header from "../../../components/user/Header/Header";
import { withRouter } from 'react-router-dom';
import restApi from '../../../api/Source';
import ReactSpinner from 'react-bootstrap-spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import './ProductList.css';


function ProductList(props) {

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [commercialAdaos, setCommercialAdaos] = useState(0);

    // the code of the product from url
    const { articleNumber } = useParams();

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    // list of unique brands found in products
    let filterBrands = [];
    const [brandFilterName, setBrandFilterName] = useState("");

    const [isBrandFilterActive, setIsBrandFilterActive] = useState(false);
    const [isPriceFilterActive, setIsPriceFilterActive] = useState(false);

    const [sortType, setSortType] = useState("");

    const navigateTo = (page) => {
        props.history.push(page);
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (localStorage.getItem("commercialAdaos") !== null && localStorage.getItem("commercialAdaos") !== undefined) {
            setCommercialAdaos(localStorage.getItem("commercialAdaos"));
        }
        console.log(commercialAdaos);
        if (articleNumber !== 'main') {
            setLoading(true);
            restApi.get("/services/articles?includeCrossReferences=" + true + "&&articleNumber=" + articleNumber).then(products => {
                setProducts(products.data)
                setFilteredProducts(products.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setError(error);
                if (error.response.status === 401) {
                    navigateTo('/');
                }
            });
        }
        if (!localStorage.getItem("cart")) {
            props.setCart([]);
        }
    }, []);

    if (products) {
        products.map(product => {
            if (filterBrands.indexOf(product.supplierBrand) === -1) {
                filterBrands.push(product.supplierBrand);
            }
        });
    }

    const addToCart = (product) => {
        props.addToCart(product);
    }

    // Filters
    const filterProductsByBrand = (brandName) => {
        if (brandName === "none") {
            setFilteredProducts(products);
        } else {
            if (filteredProducts !== products) {
                setFilteredProducts(products.filter(product => product.supplierBrand === brandName));
            } else {
                setFilteredProducts(filteredProducts.filter(product => product.supplierBrand === brandName));
            }
        }
    }

    const filterProductsByPrice = (sortType) => {
        console.log(filteredProducts);
        if (sortType === "none") {
            setFilteredProducts(products);
        } else if (sortType === "asc") {
            if (filteredProducts !== products) {
                setFilteredProducts(filteredProducts.sort((a, b) => (a.price - b.price)));
            } else {
                setFilteredProducts(products.sort((a, b) => (a.price - b.price)));
            }
        } else if (sortType === "desc") {
            if (filteredProducts !== products) {
                setFilteredProducts(filteredProducts.sort((a, b) => (b.price - a.price)));
            } else {
                setFilteredProducts(products.sort((a, b) => (b.price - a.price)));
            }
        }
        console.log(filteredProducts);
    }    

    const handleSortByBrand = (brand) => {
        filterProductsByBrand(brand);
        setIsBrandFilterActive(true);
        setBrandFilterName(brand);
    }

    const handleSortByPrice = (sortType) => {
        filterProductsByPrice(sortType);
        setIsPriceFilterActive(true);
        setSortType(sortType);
    }

    const removeBrandFilter = () => {
        filterProductsByBrand("none");
        setIsBrandFilterActive(false);
        setBrandFilterName("");
    }

    const removePriceFilter = () => {
        filterProductsByPrice("none");
        setIsPriceFilterActive(false);
        setSortType("");
    }

    return (
        <div className="page-container">
            <div id="content-wrap">
                <Header {...props} />
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
                {isLoading === false && 
                    <div className="container center products">
                        <div className="container">
                            <h6 className="delivery-message">*Produsele comandate pana la ora 10:00 din stocul BG1 se vor livra incepand cu ora 11:30</h6>
                            <h6 className="delivery-message">*Program livrare stoc RO: 09:00 - 11:00 - 13:00 - 15:00 - 17:00</h6>
                        </div>
                        {filteredProducts.length !== 0 && 
                        <div className="container">
                            <div className="filters">
                                {/* FILTERS */}
                                <div>
                                    <h6>Filtreaza produsele</h6>
                                </div>
                                <div className="input">
                                    <div className="filter white">
                                        <Dropdown>
                                            <DropdownButton
                                            alignRight
                                            title="Brand"
                                            onSelect={handleSortByBrand}>
                                                {filterBrands.map((brand) =>
                                                    <Dropdown.Item eventKey={brand}>{brand}</Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                        </Dropdown>
                                    </div>
                                    <div className="filter white">
                                        <Dropdown>
                                            <DropdownButton
                                            alignRight
                                            title="Pret"
                                            onSelect={handleSortByPrice}>
                                                <Dropdown.Item eventKey="asc">Ascendent</Dropdown.Item>
                                                <Dropdown.Item eventKey="desc">Descendent</Dropdown.Item>
                                            </DropdownButton>
                                        </Dropdown>
                                    </div>
                                </div>
                                {/* LIST OF APPLIED FILTERS */}
                                {isBrandFilterActive === true &&
                                    <div>
                                        <p>Brandul selectat este {brandFilterName}</p> 
                                    </div>
                                }
                                {isPriceFilterActive === true &&
                                    <div>
                                        <p>Sortate {sortType}</p>
                                    </div>
                                }
                                {(isPriceFilterActive === true || 
                                isBrandFilterActive === true) &&
                                    <div>
                                        <a href="#" onClick={() => {removePriceFilter();removeBrandFilter()}}>Elimina filtrele</a>
                                    </div>
                                }
                            </div>
                            
                        
                            <div className="items">
                                {filteredProducts.map((product) => (<>
                                    <div className="list-group-item item">
                                        <a className="view overlay" href={'/product/' + encodeURIComponent(product.supplierId) + '/' + encodeURIComponent(product.articleNumber)}>
                                            <div className="item-details high-resolution"> 
                                                {product.media && product.media.type === "1" ?
                                                    <div className="product-img">      
                                                        <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                                    </div>
                                                    : <div className="product-img"><img src='' className="img-responsive" alt="" /></div>
                                                }
                                                <div className="details">
                                                    <h6>{product.productName} {product.supplierBrand}</h6>
                                                    <p>{product.articleNumber}</p>
                                                </div>
                                                <div className="price">
                                                    {commercialAdaos > 0 && 
                                                        <p>Pret cu TVA: <span><b>{(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</b></span></p>
                                                    }
                                                    {commercialAdaos == 0 && 
                                                        <p>Pret cu TVA: <span><b>{product.price.toFixed(2)} RON</b></span></p>
                                                    }
                                                    {product.stockBG1 !== null && product.stockBG1 > 0 && <p>Stoc BG1: <span><b>{product.stockBG1}</b></span></p>}
                                                    {product.stockBG2 !== null && product.stockBG2 > 0 && <p>Stoc BG2: <span><b>{product.stockBG2}</b></span></p>}
                                                    {product.stockProviders !== null && product.stockProviders > 0 && <p>Stoc RO: <span><b>{product.stockProviders}</b></span></p>}
                                                </div>
                                            </div>
                                            <div className="item-details low-resolution"> 
                                                <div className="product-img-details">
                                                    {product.media && product.media.type === "1" ?
                                                            <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                                            : <img src='' className="img-responsive" alt="" />
                                                    }
                                                    <h6>{product.productName} {product.supplierBrand}</h6>
                                                    <p>{product.articleNumber}</p>
                                                </div>
                                                <div className="price">
                                                    {commercialAdaos > 0 && 
                                                        <p>Pret cu TVA: <span><b>{(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</b></span></p>
                                                    }
                                                    {commercialAdaos == 0 && 
                                                        <p>Pret cu TVA: <span><b>{product.price.toFixed(2)} RON</b></span></p>
                                                    }
                                                    {product.stockBG1 !== null && product.stockBG1 > 0 && <p>Stoc BG1: <span><b>{product.stockBG1}</b></span></p>}
                                                    {product.stockBG2 !== null && product.stockBG2 > 0 && <p>Stoc BG2: <span><b>{product.stockBG2}</b></span></p>}
                                                    {product.stockProviders !== null && product.stockProviders > 0 && <p>Stoc RO: <span><b>{product.stockProviders}</b></span></p>}
                                                </div>
                                            </div>
                                        </a>
                                        <button type="button" className="btn btn-info cart-btn" onClick={() => addToCart(product)}><FontAwesomeIcon icon={faShoppingCart} /></button>
                                    </div>
                                </>
                                ))}
                            </div>
                        </div>}
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(ProductList);