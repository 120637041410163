import React from 'react';
import { useState } from "react"

function SearchBar(props) {
  const [searchText, setSearchText] = useState('');

  const handleInput = (e) => {
    const text = e.target.value;
    setSearchText(text);
  }

  const handleEnterKeyPressed = (e) => {
    if(e.key === 'Enter') {
      getProducts(searchText);
    }
  }

  const getProducts = (articleNumber) => {
    // for admin bar, I don t have to change href
    //TODO products can be moved to ProductManagement
    if (props.isAdminBar !== null && props.isAdminBar !== undefined && props.isAdminBar === true) {
      props.getProducts(false, searchText);
    } else {
      let encodedArticleNumber = encodeURIComponent(articleNumber);
      window.location.href = "/products/" + encodedArticleNumber;
    }
  }

  return (
    <div className="col-md-4 search-bar">
      <input className="bar-input" onChange={handleInput} onKeyPress={handleEnterKeyPressed} type="text" value={searchText}
      placeholder="Introdu codul produsului..."></input>
    </div>
  );
}

export default SearchBar