import React, { useState } from "react";
import restApi from '../api/Source';
import { withRouter } from 'react-router-dom';

function Login(props) {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const navigateTo = (page) => {
    props.history.push(page);
  }

  const handleUsername = (e) => {
      const text = e.target.value
      setUsername(text)
  }

  const handlePassword = (e) => {
      const text = e.target.value
      setPassword(text)
  }

  const handleFormSubmit = async() => {
      const user_object = {
          username: username,
          password: password
      };
      
      await restApi.post("/services/authenticate", user_object).then(res => {
          if (res.data.token) {

            setWrongCredentials(false);
            localStorage.setItem("authorization", res.data.token);
            localStorage.setItem("userRole", res.data.role);
            localStorage.removeItem("cart");

            if (localStorage.getItem("authorization")) {
              localStorage.setItem("isAuthenticated", true);
              localStorage.setItem("user", username);

              if (localStorage.getItem("userRole") === "USER") {
                localStorage.setItem("societyName", res.data.societyName);
                localStorage.setItem("commercialAdaos", res.data.commercialAdaos);
                navigateTo('/products/main');
              } else if (localStorage.getItem("userRole") === "ADMIN") {
                navigateTo('/adminproducts')
              }
            }
          }
      }).catch(error => {
        setWrongCredentials(true);
        console.log("WRONG CREDENTIALS");
        console.log(error);
      });
  };

  return (
      <div>
        <div className="login-wrapper container">
            <img src="/asp.svg" alt="" />
            <div className="form-group">
              <input type="text"
                className="form-control"
                placeholder="username"
                value={username}
                onChange={handleUsername}
              />
            </div>
            <div className="form-group">
              <input type="password"
                className="form-control"
                placeholder="password"
                value={password}
                onChange={handlePassword}
              />
            </div>
            <button className="btn btn-lg btn-primary btn-block login" onClick={handleFormSubmit}>
              Login
            </button>
            {wrongCredentials === true &&
              <p>Wrong username or password</p>
            }
        </div>
      </div>
    );
}
export default withRouter(Login);