import React, { useState } from "react";
import restApi from '../../../api/Source';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactSpinner from 'react-bootstrap-spinner';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const PAGE_USERS = 'users';
const PAGE_ORDERS = 'orders';
const PAGE_PRODUCTS = 'adminproducts';
const PAGE_INVOICES = 'invoices';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

function Users(props) {

    const isLoading = props.isLoading;
    
    // ACORDION
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [role, setRole] = useState('select');

    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        societyName: '',
        tradeRegister: '',
        cif: '',
        societyAddress: '',
        deliveryAddress: '',
        county: '',
        payLimit: 0
    });

    // current selected user for edit
    const [selectedUser, setSelectedUser] = useState({});
    const [showEditUser, setShowEditUser] = useState(false);

    const [formError, setFormError] = useState(false);
    const [formResponse, setFormResponse] = useState('');

    const handleClose = () => {
        props.getUsers();
        setSelectedUser({}); 
        setShowEditUser(false);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    const navigateTo = (page) => {
        console.log(page);
        props.history.push(page);
    }

    const handleLogout = () => {
        props.handleLogout();
        navigateTo('/');
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "ADMIN") {
        navigateTo('/');
    }

    const manageUsers = () => {
        props.getUsers();
        navigateTo(PAGE_USERS);
    }

    const manageOrders = () => {
        navigateTo(PAGE_ORDERS);
    }

    const manageProducts = () => {
        navigateTo(PAGE_PRODUCTS);
    }

    const manageInvoices = () => {
        props.getInvoices();
        navigateTo(PAGE_INVOICES);
    }

    const handleRole = (e) => {
        const text = e.target.value
        setRole(text)
    }

    const updateNewUser = e => {
        setNewUser({...newUser, [e.target.name]: e.target.value});
        console.log(newUser);
    }

    const handleRemoveUser = async(removedUser) => {
        console.log(removedUser);
        await restApi.post("/services/users/delete", removedUser).then(res => {
            console.log("User removed");
            props.getUsers();
        }).catch(error => {
            console.log(error.response.data);
        });
    }

    const editUser = (user) => {
        setSelectedUser(user);
        setShowEditUser(true);
    }

    const handleChangeInEditUser = (e) => {
        const { name, value } = e.target;
        
        setSelectedUser({
            ...selectedUser,
            [name]: value,
        });
        console.log(selectedUser);
    }

    const handleEditUser = async() => {
        console.log(selectedUser);
        await restApi.post("/services/users/edit", selectedUser).then(res => {
            console.log("User updated");
            props.getUsers();
            setShowEditUser(false);
        }).catch(error => {
            console.log(error.response.data);
        });
    }

    const handleFormSubmit = async() => {
        setFormResponse('');
        const user_object = {
            username: newUser.username,
            password: newUser.password,
            role: role,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            phoneNumber: newUser.phoneNumber,
            societyName: newUser.societyName,
            tradeRegister: newUser.tradeRegister,
            cif: newUser.cif,
            societyAddress: newUser.societyAddress,
            deliveryAddress: newUser.deliveryAddress,
            county: newUser.county,
            payLimit: newUser.payLimit
        };
        if (user_object.username && user_object.password && role !== 'select') {
            setFormError(false);
            await restApi.post("/services/register", user_object).then(res =>  {
                if (res.data.username) {
                    console.log("User added");
                    setNewUser({
                        username: '',
                        password: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        societyName: '',
                        tradeRegister: '',
                        cif: '',
                        societyAddress: '',
                        deliveryAddress: '',
                        county: '',
                        payLimit: 0
                    });
                    setRole('select');
                    setFormResponse("Utilizator adaugat cu succes!");
                } else {
                    setNewUser({
                        username: '',
                        password: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        societyName: '',
                        tradeRegister: '',
                        cif: '',
                        societyAddress: '',
                        deliveryAddress: '',
                        county: '',
                        payLimit: 0
                    });
                    setRole('select');
                    setFormResponse("Ceva nu a mers. Incearca din nou.");
                }
            }).catch(error => {
                console.log(error.response.data);
                setNewUser({
                    username: '',
                    password: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    societyName: '',
                    tradeRegister: '',
                    cif: '',
                    societyAddress: '',
                    deliveryAddress: '',
                    payLimit: 0
                });
                setRole('select');
                setFormResponse(error.response.data);
            });
        } else {
            setFormError(true);
        }
    };

    return (
        <div className="admin">
            <nav className="navbar navbar-expand-lg navbar-light">
            
                <div className="row justify-content-center col-md-4">
                    <h3>Utilizatori</h3>
                </div>
                
                <div className="row justify-content-center col-md-4" id="navbarSupportedContent">

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageProducts()}>Produse</button>
                        </li>
                        <li className="nav-item active">
                            <button className="nav-link" onClick={() => manageUsers()}>Utilizatori</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageOrders()}>Comenzi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => manageInvoices()}>Facturi</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
            </nav>
            {isLoading === false && 
            <div className="list-group-item list-group-item-action flex-column align-items-start">

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                    <Typography className={classes.heading}>Vizualizeaza toti utilizatorii</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Nume Societate</th>
                                    <th scope="col">Rol</th>
                                    <th scope="col">Sold</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.users.map((user) => (
                                        <tr>
                                            <td>{user.societyName}</td>
                                            <td>{user.role}</td>
                                            <td>{user.sold}</td>
                                            <td>
                                            <button type="button" class="btn btn-primary" 
                                                onClick={() => editUser(user)}>Edit user
                                            </button>
                                            <button type="button" class="btn btn-danger" 
                                                onClick={() => handleRemoveUser(user)}>Remove user
                                            </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    >
                    <Typography className={classes.heading}>Adauga utilizator</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {/* TODO fields mandatory */}
                            <div>
                                <input type="text"
                                    className="form-control"
                                    placeholder="username"
                                    name="username"
                                    value={newUser.username}
                                    onChange={updateNewUser}
                                />
                                <input type="password"
                                    className="form-control"
                                    placeholder="password"
                                    name="password"
                                    value={newUser.password}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Prenume"
                                    name="firstName"
                                    value={newUser.firstName}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Nume"
                                    name="lastName"
                                    value={newUser.lastName}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Telefon"
                                    name="phoneNumber"
                                    value={newUser.phoneNumber}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Nume Societate"
                                    name="societyName"
                                    value={newUser.societyName}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Registru Comert"
                                    name="tradeRegister"
                                    value={newUser.tradeRegister}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="CIF"
                                    name="cif"
                                    value={newUser.cif}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Adresa Societate"
                                    name="societyAddress"
                                    value={newUser.societyAddress}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Adresa Livrare"
                                    name="deliveryAddress"
                                    value={newUser.deliveryAddress}
                                    onChange={updateNewUser}
                                />
                                <input type="text"
                                    className="form-control"
                                    placeholder="Judet"
                                    name="county"
                                    value={newUser.county}
                                    onChange={updateNewUser}
                                />
                                <input type="number"
                                    className="form-control"
                                    placeholder="Termen Plata"
                                    name="payLimit"
                                    value={newUser.payLimit}
                                    onChange={updateNewUser}
                                />
                            </div>
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="select1">Alege optiune</label>
                                    <select value={role} onChange={handleRole} className="form-control">
                                    <option value="select">Select an Option</option>
                                    <option value="USER">User</option>
                                    <option value="ADMIN">Admin</option>
                                    </select>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block" onClick={handleFormSubmit}>
                                Adauga Utilizator
                                </button>
                                {formError === true && !formResponse &&
                                    <p>Toate campurile sunt obligatorii</p>
                                }
                                {
                                    formError === false && formResponse &&
                                    <p>{formResponse}</p>
                                }
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Modal show={showEditUser} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                    <Modal.Title>Editare User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'overflow-y': 'auto'}}>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Username</th>
                                    <th scope="col">CIF</th>
                                    <th scope="col">Delivery Address</th>
                                    <th scope="col">Judet</th>
                                    <th scope="col">Pay Limit</th>
                                    <th scope="col">Society Address</th>
                                    <th scope="col">Society Name</th>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">Trade Register</th>
                                </tr>
                            </thead>
                            {/* TODO a form or something to deal with all the changes in parallel, not one by one */}
                            <tbody>
                                <tr>
                                    <td>{selectedUser.username}</td>
                                    <td><input type="text" placeholder={selectedUser.cif} name="cif" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.deliveryAddress} name="deliveryAddress" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.county} name="county" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="number" placeholder={selectedUser.payLimit} name="payLimit" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.societyAddress} name="societyAddress" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.societyName} name="societyName" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.firstName} name="firstName" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.lastName} name="lastName" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.phoneNumber} name="phoneNumber" onChange={handleChangeInEditUser} /></td>
                                    <td><input type="text" placeholder={selectedUser.tradeRegister} name="tradeRegister" onChange={handleChangeInEditUser} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditUser}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>}
        </div>
    );
}

export default withRouter(Users);