import React, {useState, useEffect} from 'react'
import restApi from '../../../api/Source';
import Footer from "../../../components/user/Footer/Footer";
import Header from "../../../components/user/Header/Header";
import { withRouter } from 'react-router-dom';
import ReactSpinner from 'react-bootstrap-spinner';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './MyAccount.css';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

function MyAccount(props) {

    const [isLoading, setLoading] = useState(true);
    // ACORDION
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    
    const [commercialAdaos, setCommercialAdaos] = React.useState({'value': 0});

    const [manageOption, setManageOption] = useState({
        displayMyOrders: false,
        displayMySettings: false
    });

    let statusMapping = {PENDING: "Comenzi plasate", 
        SENT: "Comenzi acceptate", 
        PROCESSED: "Comenzi facturate",
        DELIVERED: "Comenzi livrate",
        RETURNED: "Comenzi returnate",
        CANCELLED: "Comenzi anulate"};

    
    useEffect(() => {
        window.scroll(0, 0);
        setLoading(true);
        restApi.get("/services/user/" + localStorage.getItem("user") + "/commercial-adaos").then(commercialAdaos => {
            setCommercialAdaos(commercialAdaos.data);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.response.status === 401) {
                navigateTo('/');
            }
        });
    }, []);


    const navigateTo = (page) => {
        props.history.push(page);
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }

    const viewMyOrders = () => {
        setLoading(true);
        props.getUserOrders();
        setManageOption({...manageOption, 'displayMyOrders': true, 'displayMySettings': false});
        setLoading(false);
    }

    const viewMySettings = () => {
        setManageOption({...manageOption, 'displayMyOrders': false, 'displayMySettings': true});
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleEditCommercialAdaos = (e) => {
        console.log(e.target.value);
        if (e.target.value !== null && e.target.value !== undefined && e.target.value !== '') {
            setCommercialAdaos({...commercialAdaos, 'value': e.target.value})
        } else {
            setCommercialAdaos({...commercialAdaos, 'value': 0})
        }
    }

    const editCommercialAdaos = () => {
        restApi.put("/services/user/" + localStorage.getItem("user") + "/commercial-adaos", commercialAdaos).then(res => {
            console.log(res);
            localStorage.setItem("commercialAdaos", commercialAdaos.value);
        }).catch(error => {
            console.log("Something has failed");
        });
      }

    return (
        <div className="page-container my-account">
            <div id="content-wrap">
                <Header {...props} />

                <nav className="navbar navbar-expand-lg navbar-light my-account-menu">
                    <div className="row justify-content-center col-md-3" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item orders">
                                <button className="nav-link btn" onClick={() => viewMyOrders()}>Comenzile mele</button>
                            </li>
                            <li className="nav-item settings">
                                <button className="nav-link btn settings" onClick={() => viewMySettings()}>Setari</button>
                            </li>
                        </ul>
                    </div>
                </nav>
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
                {isLoading === false && manageOption.displayMyOrders === true && 
                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                        {props.userOrders.map((userOrder) => (
                            userOrder.orders !== null ?
                            <Accordion expanded={expanded === (userOrder.status)} onChange={handleChange(userOrder.status)}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography className={classes.heading}>{statusMapping[`${userOrder.status}`]}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {userOrder.orders.map((order) => (
                                            <div className="orders">
                                                <div>
                                                    <h6><b>Mentiuni:</b> <span>{order.description}</span></h6>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">ArticleNr</th>
                                                                <th scope="col">ProductName</th>
                                                                <th scope="col">SupplierBrand</th>
                                                                <th scope="col">Cantitate</th>
                                                                <th scope="col">Data trimitere comanda</th>
                                                                <th scope="col">Pret</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {order.products.map((product) => (
                                                                <tr>
                                                                    <td>{product.articleNumber}</td>
                                                                    <td>{product.productName}</td>
                                                                    <td>{product.supplierBrand}</td>
                                                                    <td>{product.quantity}</td>
                                                                    <td>{product.submittedDate}</td>
                                                                    <td>{product.price}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ))}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion> : null
                        ))}
                    </div>
                }
                {isLoading === false && manageOption.displayMySettings === true && 
                    <div className="container settings">
                        <label><b>Adaos comercial</b></label><br/>
                        <input type="number" step="any" placeholder={commercialAdaos.value} onChange={(e) => handleEditCommercialAdaos(e)} />
                        <button type="button" className="btn btn-success" onClick={() => editCommercialAdaos()}>Salveaza</button>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(MyAccount);