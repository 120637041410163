import React, {useState} from 'react'
import SearchBar from "../SearchBar/SearchBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import './Header.css';

// https://www.robinwieruch.de/react-dropdown/ -> dropdown menu

const PAGE_PRODUCTS = '/products/main';
const PAGE_PARTNERS = '/partners';
const PAGE_MENU = '/menu';
const PAGE_MY_ACCOUNT = '/account';
const PAGE_CART = '/cart';

function Header(props) {

    const [acccountMenuOpen, setAcccountMenuOpen] = useState(false);

    const navigateTo = (page) => {
        props.history.push(page);
    }

    const handleLogout = () => {
        localStorage.clear();
        props.setCart([]);
        localStorage.setItem("isAuthenticate", false);
    }

    const handleOpenAccountMenu = () => {
        setAcccountMenuOpen(!acccountMenuOpen);
      };
    

    return (
        <nav className="row justify-content-center navbar navbar-expand-lg navbar-light">
            <div className="container">
                <div className="logo-section">
                    <a href="/products/main"><img className="logo" src="/asp.svg" alt="" /></a>
                </div>

                <SearchBar />
                
                <div className="menu-section" id="navbarSupportedContent">

                    <ul className="navbar-nav right-menu">
                        <li className="nav-item border-right">
                            <button className="nav-link btn btn-partners" onClick={() => navigateTo(PAGE_PARTNERS)}>Parteneri</button>
                        </li>
                        <li className="nav-item active border-right">
                            <button className="nav-link btn btn-products" onClick={() => navigateTo(PAGE_PRODUCTS)}>Produse</button>
                        </li>
                        <li className="nav-item active border-right">
                            <button className="nav-link btn btn-products" onClick={() => navigateTo(PAGE_MENU)}>Meniu</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-cart" onClick={() => navigateTo(PAGE_CART)}>{props.getCartTotalProductsNumber()} <FontAwesomeIcon icon={faShoppingCart} /><span className="sr-only">(current)</span></button>
                        </li>
                    </ul>
                </div>

                <div className="dropdown">
                    <button className="account-menu" onClick={handleOpenAccountMenu}>{localStorage.getItem("societyName")} <FontAwesomeIcon icon={faCaretDown} /></button>
                    {acccountMenuOpen ? (
                        <ul className="menu">
                            <li className="menu-item">
                                <button onClick={() => navigateTo(PAGE_MY_ACCOUNT)}>Contul Meu</button>
                            </li>
                            <li className="menu-item">
                                <button onClick={() => handleLogout()}>Logout</button>
                            </li>
                        </ul>
                    ) : null}
                </div>
            </div>

            
        </nav>
    );
}

export default Header;