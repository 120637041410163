import React from 'react';

import { withRouter } from 'react-router-dom';
import Header from "../../../components/user/Header/Header";

function Partners(props) {

    const navigateTo = (page) => {
        props.history.push(page);
    }

    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }
    
    return (
        <div className="page-container">
            <div id="content-wrap">
                <Header {...props} />
            </div>
            <div className="container">
                <div className="row text-center">
                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.blue-print.de/home/">
                                <img src="/media/img/brands/BL_PRINT_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://bg.bosch-automotive.com/bg/">
                                <img src="/media/img/brands/BOSCH_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.brembo.com/en">
                                <img src="/media/img/brands/BREMBO_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.comline.uk.com/">
                                <img src="/media/img/brands/COMLINE_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.contitech.de/">
                                <img src="/media/img/brands/CONTITECH_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.dynamaxoil.com/en/home/">
                                <img src="/media/img/brands/DYNAMAX_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.elring.com/">
                                <img src="/media/img/brands/ELRING_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.eurol.com/en/">
                                <img src="/media/img/brands/EUROL_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.febi.com/en/">
                                <img src="/media/img/brands/FEBI_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.fuchs.com/">
                                <img src="/media/img/brands/FUCHS_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.galfer-aftermarket.com/">
                                <img src="/media/img/brands/GALFER_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.gates.com/">
                                <img src="/media/img/brands/GATES_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.gkn.com/">
                                <img src="/media/img/brands/GKN_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.hepu.de/en/">
                                <img src="/media/img/brands/HEPU_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://catalog.mahle-aftermarket.com/eu/">
                                <img src="/media/img/brands/KNECHT_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://kyb-europe.com/">
                                <img src="/media/img/brands/KYB_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://aftermarket.zf.com/go/en/lemfoerder/about-us/the-brand/">
                                <img src="/media/img/brands/LMI_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.lpr.it/index.php?&amp;lang=en">
                                <img src="/media/img/brands/LPR_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.schaeffler.us/content.schaeffler.us/us/company/company.jsp">
                                <img src="/media/img/brands/LUK_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.meyle.com/en/">
                                <img src="/media/img/brands/MEYLE_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.monroe.com/en-emea">
                                <img src="/media/img/brands/MONROE_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="#">
                                <img src="/media/img/brands/NRG_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.osram.com">
                                <img src="/media/img/brands/OSRAM_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="#">
                                <img src="/media/img/brands/PREMIUM_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.schaeffler.de/content.schaeffler.de/en/products-and-solutions/automotive-aftermarket/index.jsp">
                                <img src="/media/img/brands/RUVILLE_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://aftermarket.zf.com/go/en/sachs/home/">
                                <img src="/media/img/brands/SACHS_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ntn-snr.com/">
                                <img src="/media/img/brands/SNR_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.valeo.com">
                                <img src="/media/img/brands/VALEO_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.varta-automotive.com/en-gb">
                                <img src="/media/img/brands/VARTA_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.vdo.com/">
                                <img src="/media/img/brands/VDO_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://wixfilters.com/">
                                <img src="/media/img/brands/WIX_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ngksparkplugs.com/">
                                <img src="/media/img/brands/NGK_L.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.beruparts.eu/">
                                <img src="/media/img/brands/BERU.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.ate-brakes.com/">
                                <img src="/media/img/brands/ATE.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.alliednippon.com/">
                                <img src="/media/img/brands/ALLIED-NIPPON.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.bilstein.com/us/en/">
                                <img src="/media/img/brands/BILSTEIN.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.crceurope.com/crc/">
                                <img src="/media/img/brands/CRC.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.fae.es/en/">
                                <img src="/media/img/brands/FAE.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.hella.com/hella-com/index.html">
                                <img src="/media/img/brands/HELLA.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.schaeffler.de/content.schaeffler.de/de/index.jsp">
                                <img src="/media/img/brands/INA.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.hepu.de/ipd-en/">
                                <img src="/media/img/brands/IPD.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ms-motorservice.com/en/">
                                <img src="/media/img/brands/KS.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.mahle.com/">
                                <img src="/media/img/brands/MAHLE.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.skf.com/bg/index.html">
                                <img src="/media/img/brands/SKF.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.stabilus.com/">
                                <img src="/media/img/brands/STABILUS.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://pries.de/">
                                <img src="/media/img/brands/TOPRAN.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.zf.com/mobile/en/homepage/homepage.html">
                                <img src="/media/img/brands/ZF.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.zf.com/mobile/en/homepage/homepage.html">
                                <img src="/media/img/brands/ZIMMERMANN.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.arnotteurope.com/">
                                <img src="/media/img/brands/ARNOT.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.behrhellaservice.com/behr-hella-service/en/index.html">
                                <img src="/media/img/brands/BEHR_HELLA_SERVICE.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://densoautoparts.com/">
                                <img src="/media/img/brands/DENSO.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.schaeffler.bg/content.schaeffler.bg/bg/index.jsp">
                                <img src="/media/img/brands/FAG.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.fare-auto.com/en/">
                                <img src="/media/img/brands/FARE.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.metelli.com/en/Brands/Graf">
                                <img src="/media/img/brands/GRAF.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="http://www.g-autoparts.com/">
                                <img src="/media/img/brands/GSP.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://rymec.com/">
                                <img src="/media/img/brands/RYMEC.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="#">
                                <img src="/media/img/brands/SPIDAN.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://turbomotor.es/en/">
                                <img src="/media/img/brands/TURBO_MOTOR.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.borgwarner.com/home">
                                <img src="/media/img/brands/WAHLER.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ngkntk.com/bg/">
                                <img src="/media/img/brands/NTK.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.hartphp.com.pl/">
                                <img src="/media/img/brands/HART.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ms-motorservice.com/en/company/our-brands/pierburg/">
                                <img src="/media/img/brands/PIERB.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="https://www.ms-motorservice.com/en/company/our-brands/kolbenschmidt/">
                                <img src="/media/img/brands/KOLB.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>


                    <div className="col-6 col-sm-3 col-md-2 box">
                        <div className="imgport">
                            <a target="_blank" href="#">
                                <img src="/media/img/brands/APLUS.png?v=3" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Partners)