import React, {useState, useEffect} from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import Partners from './pages/user/Partners/Partners';
import Menu from './pages/user/Menu/Menu';
import ProductList from './pages/user/ProductList/ProductList';
import MyAccount from './pages/user/MyAccount/MyAccount';
import restApi from './api/Source';
import Cart from './pages/user/Cart/Cart';
import Users from './pages/admin/Users/Users';
import ProductManagement from './pages/admin/ProductManagement/ProductManagement';
import Orders from './pages/admin/Orders/Orders';
import Invoices from './pages/admin/Invoices/Invoices';
import Product from './pages/user/Product/Product';
import Login from "./components/Login";
import Error from './components/Error';
import './index.css';

function App(props) {
  const [cart, setCart] = useState([]);
  const [users, setUsers] = useState([]);
  const [userOrders, setUserOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [products, setProducts] = useState([]);
  const [isBrandFilterActive, setIsBrandFilterActive] = useState(false);
  const [isPriceFilterActive, setIsPriceFilterActive] = useState(false);
  const [exchange, setExchange] = useState({});
  const [pricesStockForBrand, setPricesStockForBrand] = useState();


  useEffect(() => {
    let localCart = localStorage.getItem("cart");
    //turn it into js
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (localCart) {
      setCart(localCart);
    }
  }, [])

  if (error !== undefined) {
    if (error.response.status === 401) {
      return <div>
          <p>Sesiunea dvs. a expirat. Va rugam sa va intoarceti la pagina de <span><a href="/" >Login</a></span>.</p>
        </div>;
    } else {
      return <div className="App">A aparut o eroare. Va rugam sa incercati din nou.</div>;
    }
  }

  const getProducts = (includeCrossReferences, articleNumber) => {
    setLoading(true);
    restApi.get("/services/articles?includeCrossReferences=" + includeCrossReferences + "&&articleNumber=" + articleNumber).then(products => {
      setProducts(products.data)
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const getExchange = async() => {
    setLoading(true);
    await restApi.get("/services/exchange").then(exchange => {
      setExchange(exchange.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }
  const editExchange = async() => {
    setLoading(true);
    await restApi.post("/services/exchange", exchange).then(res => {
      console.log(res);
      getExchange();
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const generatePricesStocksForBrand = async() => {
    setLoading(true);
    await restApi.get("/services/business/increase?supplierBrand=" + pricesStockForBrand).then(res => {
      console.log(res);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const generatePricesStocksForAllBrands = async() => {
    setLoading(true);
    await restApi.get("/services/business/increase?supplierBrand=").then(res => {
      console.log(res);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const getUsers = async() => {
    setLoading(true);
    await restApi.get("/services/users").then(users => {
      setUsers(users.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const getInvoices = async() => {
    setLoading(true);
    await restApi.get("/services/orders/invoices").then(invoices => {
      setInvoices(invoices.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    });
  }

  const getUserOrders = async() => {
    setLoading(true);
    await restApi.get("/services/user/" + localStorage.getItem("user") + "/orders").then(orders => {
      setUserOrders(orders.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error);
    })
  }

  const editProduct = async(selectedEditProduct) => {
    await restApi.post("/services/product/edit", selectedEditProduct).then(res => {
      console.log(res);
      getProducts(false, selectedEditProduct.articleNumber);
    }).catch(error => {
      setLoading(false);
      setError(error);
    })
  }

  const editProvider = async(selectedEditProvider) => {
    await restApi.post("/services/providers/edit", selectedEditProvider).then(res => {
      console.log(res);
      getProducts(false, selectedEditProvider.articleNumber);
    }).catch(error => {
      setLoading(false);
      setError(error);
    })
  }

  const addToCart = (product) => {
    let updatedCart = [...cart];

    let productInCart = updatedCart.find(
      (item) => product.articleNumber === item.articleNumber && 
                product.supplierId === item.supplierId);

    if (productInCart) {
      productInCart.quantity++;
    } else {
      productInCart = {
        ...product,
        quantity: 1
      };
      updatedCart.push(productInCart);
    }

    setCart(updatedCart);
    // save to localStorage
    let stringCart = JSON.stringify(updatedCart);
    localStorage.setItem("cart", stringCart);
  };

  const getCartTotalProductsNumber = () => {
    return cart.reduce((sum, {quantity}) => sum + quantity, 0);
  }

  const handleLogout = () => {
    localStorage.clear();
    setCart([]);
    localStorage.setItem("isAuthenticate", false);
  }

  return (
    <BrowserRouter>
      <div className="app-main">
        <Switch>
          <Route path="/" render={() => <Login />} exact />
          <Route path="/partners" render={() => <Partners handleLogout={handleLogout} setCart={setCart} getCartTotalProductsNumber={getCartTotalProductsNumber} />} />
          <Route path="/menu" render={() => <Menu handleLogout={handleLogout} setCart={setCart} getCartTotalProductsNumber={getCartTotalProductsNumber} addToCart={addToCart} />} />
          <Route path="/products/:articleNumber" render={() => <ProductList setCart={setCart} getCartTotalProductsNumber={getCartTotalProductsNumber} isBrandFilterActive={isBrandFilterActive} setIsBrandFilterActive={setIsBrandFilterActive} isPriceFilterActive={isPriceFilterActive} setIsPriceFilterActive={setIsPriceFilterActive} addToCart={addToCart} handleLogout={handleLogout} />} />
          <Route path="/account" render={() => <MyAccount userOrders={userOrders} getUserOrders={getUserOrders} getCartTotalProductsNumber={getCartTotalProductsNumber} handleLogout={handleLogout} setCart={setCart} isLoading={isLoading} />} />
          <Route path="/product/:supplierId/:productCode" render={() => <Product getCartTotalProductsNumber={getCartTotalProductsNumber} addToCart={addToCart} setCart={setCart} handleLogout={handleLogout} /> } />
          <Route path="/cart" render={() => <Cart cart={cart} setCart={setCart} getCartTotalProductsNumber={getCartTotalProductsNumber} handleLogout={handleLogout} />} />
          {/* TODO /admin/users */}
          <Route path="/adminproducts" render={() => <ProductManagement handleLogout={handleLogout} getUsers={getUsers} getInvoices={getInvoices} getProducts={getProducts} setProducts={setProducts} products={products} editProduct={editProduct} editProvider={editProvider} exchange={exchange} getExchange={getExchange} setExchange={setExchange} editExchange={editExchange} pricesStockForBrand={pricesStockForBrand} setPricesStockForBrand={setPricesStockForBrand} generatePricesStocksForBrand={generatePricesStocksForBrand} generatePricesStocksForAllBrands={generatePricesStocksForAllBrands} isLoading={isLoading} setLoading={setLoading} />} />
          <Route path="/users" render={() => <Users handleLogout={handleLogout} users={users} getUsers={getUsers} getInvoices={getInvoices} setUsers={setUsers} isLoading={isLoading} />} />
          <Route path="/orders" render={() => <Orders handleLogout={handleLogout} getUsers={getUsers} getInvoices={getInvoices} />} />
          <Route path="/invoices" render={() => <Invoices handleLogout={handleLogout} invoices={invoices} getInvoices={getInvoices} getUsers={getUsers} isLoading={isLoading} />} />
          <Route component={Error} />

        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
