import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Footer from "../../../components/user/Footer/Footer";
import restApi from '../../../api/Source';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import ReactSpinner from 'react-bootstrap-spinner';
import Header from "../../../components/user/Header/Header";

import './Product.css';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

function Product(props) {

    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [parts, setParts] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [oemNumbers, setOemNumbers] = useState([]);
    const [commercialAdaos, setCommercialAdaos] = useState(0);

    // the code of the product from url
    const { supplierId, productCode } = useParams();

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        window.scroll(0, 0);
        if (localStorage.getItem("commercialAdaos") !== null && localStorage.getItem("commercialAdaos") !== undefined) {
            setCommercialAdaos(localStorage.getItem("commercialAdaos"));
        }
        setLoading(true);
        restApi.get("/services/articles?includeCrossReferences=" + true + "&&articleNumber=" + productCode).then(products => {
            setProducts(products.data);
            products.data.map((item) => {
                if (decodeURIComponent(productCode) === item.articleNumber && Number(decodeURIComponent(supplierId)) === item.supplierId) {
                    console.log("DAAA");
                    setProduct(item);
                    restApi.get("/services/articles/" + item.id + "/parts").then(partsResponse => {
                        setParts(partsResponse);
                        console.log(partsResponse);
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                        setError(error);
                        if (error.response.status === 401) {
                            navigateTo('/');
                        }
                    });
                    setProductDetails(item.articleCriteria != null ? item.articleCriteria.split(';') : []);
                    setOemNumbers(item.oemNumbers != null ? item.oemNumbers.split('\n') : []);
                    if (oemNumbers.length === 1) {
                        oemNumbers = item.oemNumbers != null ? item.oemNumbers.split(';') : [];
                    }
                }
            });
        }).catch(error => {
            setLoading(false);
            setError(error);
            if (error.response.status === 401) {
                navigateTo('/');
            }
        });
    }, []);

    const navigateTo = (page) => {
        props.history.push(page);
    }
    
    if (localStorage.getItem("isAuthenticated") === false || localStorage.getItem("userRole") !== "USER") {
        navigateTo('/');
    }

    const addToCart = (product) => {
        props.addToCart(product);
    }

    const setPart = (part) => {
        setLoading(true);
        restApi.get("/services/article/" + part.articleNumber + "/sup/" + part.supplierId).then(relatedProduct => {
            setProduct(relatedProduct.data);
        }).catch(error => {
            setLoading(false);
            setError(error);
        });
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    return (
        <div className="page-container">
            <div id="content-wrap">
                <Header {...props} />
                {isLoading === true && 
                    <ReactSpinner type="border" color="primary" size="2" />
                }
                {isLoading === false && 
                <div className="list-group container product">
                    
                    <div className="mask flex-center rgba-red-strong row">
                        <div className="float-left details col-md-4">
                            {product.media && product.media.type === "1" ? 
                                <div className="product-img">
                                    <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                </div> : null}
                            <div className="product-info">
                                <p>{product.productName} {product.supplierBrand}</p>
                                <p>{product.articleNumber}</p>
                            </div>
                        </div>
                        <div className="float-right stock col-md-4">
                            {product.stockBG1 != null && product.stockBG1 > 0 && <p>Stoc BG1: {product.stockBG1}</p>}
                            {product.stockBG2 != null && product.stockBG2 > 0 && <p>Stoc BG2: {product.stockBG2}</p>}
                        </div>
                        <div className="float-right price col-md-4">
                            {commercialAdaos > 0 && 
                                <p>Pret cu TVA: {(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</p>
                            }
                            {commercialAdaos == 0 && 
                                <p>Pret cu TVA: {product.price.toFixed(2)} RON</p>
                            }
                            <button type="button" class="btn btn-primary order" onClick={() => addToCart(product)}>Adauga in cos</button>
                        </div>
                    </div>
                    <div className="list-group-item list-group-item-action flex-column align-items-start accordion">

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                            <Typography className={classes.heading}>Detalii Produs</Typography>
                            {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <ul className="list-group list-group-flush">
                                        {productDetails.map((detail) => (
                                            <>
                                                <li className="list-group-item">{detail}</li>
                                            </>
                                        ))}
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            >
                            <Typography className={classes.heading}>Coduri OE</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <ul className="list-group list-group-flush">
                                        {oemNumbers.map((oemNumber) => (
                                            <>
                                                <li className="list-group-item">{oemNumber}</li>
                                            </>
                                        ))}
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            > 
                            <Typography className={classes.heading}>Echivalente</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="list-group">
                                        {products.map((product) => (
                                            <>
                                            
                                                <a className="list-group-item" href={'/product/' + product.supplierId + '/' + product.articleNumber} onClick={() => setProduct(product)}>
                                                    {/* {product.media && product.media.type === "1" ? 
                                                        <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                                    : null} */}
                                                    <div>
                                                        <div className="float-left">
                                                            <h6>{product.productName}</h6>
                                                            <p>{product.supplierBrand}</p>
                                                            <p>{product.articleNumber}</p>
                                                        </div>
                                                        <div className="float-right">
                                                            {commercialAdaos > 0 && 
                                                                <p>{(product.price + (product.price * commercialAdaos / 100)).toFixed(2)} RON</p>
                                                            }
                                                            {commercialAdaos == 0 && 
                                                                <p>{product.price.toFixed(2)} RON</p>
                                                            }
                                                            {product.stockBG1 != null && product.stockBG1 > 0 && <p>Stoc BG1: {product.stockBG1}</p>}
                                                            {product.stockBG2 != null && product.stockBG2 > 0 && <p>Stoc BG2: {product.stockBG2}</p>}
                                                        </div>
                                                    </div>
                                                </a>
                                            
                                            </>
                                        ))}
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            >
                            <Typography className={classes.heading}>Piese in set</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {parts !== null && parts.length > 0 && parts.map((part) => (
                                        <>
                                            <div className="list-group">
                                                {/* {product.media && product.media.type === "1" ? 
                                                    <img src={`/media/img/${product.media.source}`} className="img-responsive" alt = "" />
                                                : null} */}
                                                <a className="list-group-item" href="#" onClick={() => setPart(part)}>
                                                    <div>
                                                        <div className="float-left">
                                                            <h6>{part.productName}</h6>
                                                            <p>{part.supplierBrand}</p>
                                                            <p>{part.articleNumber}</p>
                                                        </div>
                                                        <div className="float-right">
                                                            <p>{product.price}RON</p>
                                                            {product.stockBG1 != null && product.stockBG1 > 0 && <p>Stoc BG1: {product.stockBG1}</p>}
                                                            {product.stockBG2 != null && product.stockBG2 > 0 && <p>Stoc BG2: {product.stockBG2}</p>}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    ))}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(Product);