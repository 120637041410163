import React from "react";

import './Footer.css';

function Footer() {
  return (
    <div className="row justify-content-center footer">
      <div className="container row">
        <div className="col-md-4">
            <h6>Date generale</h6>
            <ul className="company">
              <li><a target="_blank" href="/media/politica_de_confidentialitate.pdf">Politica de confidentialitate</a></li>
              <li><a target="_blank" href="/media/gdpr.pdf">GDPR</a></li>
              <li><a target="_blank" href="/media/formular_garantii.pdf">Formular garantii</a></li>
              <li><a target="_blank" href="/media/formular_retur.pdf">Formular retur</a></li>
            </ul>
        </div>
        <div className="col-md-4">
            <h6>Contact</h6>
            <ul className="company">
              <li>office@asparts.ro</li>
              <li>0726733707</li>
            </ul>
        </div>
        <div className="col-md-4">
            <h6>Companie</h6>
            <ul className="company">
              <li>Automotive Spare Parts S.R.L.</li>
              <li>RO38837182</li>
              <li>J40/1883/2018</li>
              <li>Str. Industriilor 56 Bucuresti Sect 3</li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;